<script>
export default {
  name: "navbar-layout",
  props: {
    logo: String
  }
}
</script>

<template>
  <nav class="navbar main_navbar navbar-expand-lg navbar-template-2 new-navbar">
    <router-link to="/" class="logo">
      <img :src="logo" class="image_logo" alt="logo">
    </router-link>
  </nav>
</template>

<style lang="scss" scoped>
  .navbar {
    .logo {
      display: block;
      height: 60px;
      object-fit: scale-down;
      margin: 0 auto;
      .image_logo {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>