<template>
  <div class="container">
    <ul
      class="nav nav-pills px-0 mx-0 mb-3"
      id="pills-tab"
      role="tablist"
      :class="{ fixed: isTabFixed }"
      ref="tabMenu"
    >
      <!-- start tab -->
      <li class="nav-item" role="presentation">
        <!-- v-if="this.$route.params.page == 0" -->
        <button
          class="nav-link active"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="true"
          @click="ScrollUp"
        >
          {{ $t("rooms") }}
        </button>
      </li>
      <!-- finish tab -->

      <!-- start tab -->
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-sort-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-sort"
          type="button"
          role="tab"
          aria-controls="pills-sort"
          aria-selected="false"
          @click="ScrollUp"
        >
          {{ $t("sort_hotel") }}
        </button>
      </li>
      <!-- finish tab -->

      <!-- start tab -->
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-services-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-services"
          type="button"
          role="tab"
          aria-controls="pills-services"
          aria-selected="false"
          @click="ScrollUp"
        >
          {{ $t("services") }}
        </button>
      </li>
      <!-- finish tab -->

      <!-- start tab -->
      <!-- <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-rate-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-rate"
          type="button"
          role="tab"
          aria-controls="pills-rate"
          aria-selected="false"
          @click="ScrollUp"
        >
          {{ $t("rates") }}
        </button>
      </li> -->
      <!-- finish tab -->
    </ul>
  </div>
  <div class="tab-content" id="pills-tabContent">
    <div v-if="noRooms" class="container px-4 pb-3">
      <!-- <img src="@/assets/media/image/" alt="no rooms available"> -->
      <!-- <h6 class="text-center">{{ $t("no_rooms_available") }}</h6> -->
      <a
          :href="`https://wa.me/${whatsappLink}`"
          target="_blank"
          class="btn PrimaryButton w-fit-content"
      >{{ $t("contact us") + " " + $t("for_book") }}</a
      >
    </div>
    <!-- start tab -->
    <div
      class="tab-pane fade show active"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <rooms-option :hotelData="hotelData" @roomPrices="checkRooms" />
      <!-- <div class="container" v-else>
        <p class="text-center fs-1 text-muted">{{ $t("no_rooms") }}</p>
      </div> -->
    </div>
    <!-- finish tab -->

    <!-- start tab -->
    <div
      class="tab-pane fade"
      id="pills-sort"
      role="tabpanel"
      aria-labelledby="pills-sort-tab"
    >
      <about-hotel :about="hotelData.description" />
    </div>
    <!-- finish tab -->

    <!-- start tab -->
    <div
      class="tab-pane fade"
      id="pills-services"
      role="tabpanel"
      aria-labelledby="pills-services-tab"
    >
      <Services
        :amenities="hotelData.amenities"
        :entertainments="hotelData.entertainments"
        :business="hotelData.business"
      />
    </div>
    <!-- finish tab -->

    <!-- start tab -->
    <!-- <div
      class="tab-pane fade"
      id="pills-rate"
      role="tabpanel"
      aria-labelledby="pills-rate-tab"
    >
      <rate />
    </div> -->
    <!-- finish tab -->
  </div>
</template>

<script>
// import generalDetials from "./generalDetials.vue";
// import attachment from "./attachment.vue";
// import rate from "./rate.vue";
import Services from "./services.vue";
import aboutHotel from "./aboutHotel.vue";
import RoomsOption from "./roomsOption.vue";
import {mapState} from "pinia";
import {organizationSettings} from "@/store/modules/organizationSettings";

export default {
  data() {
    return {
      isTabFixed: false,
      tabMenuOffsetTop: 0,
      noRooms:false,
      adults:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 1
              : JSON.parse(localStorage.getItem("hotelDataSearch")).rooms_guests[0]
                  .adults_count,
      childrens:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 1
              : JSON.parse(localStorage.getItem("hotelDataSearch")).rooms_guests[0]
                  .children_count,
      rooms:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 1
              : JSON.parse(localStorage.getItem("hotelDataSearch")).no_of_rooms,
      checkIn:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? new Date()
              : JSON.parse(localStorage.getItem("hotelDataSearch")).check_in,
      checkOut:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? new Date()
              : JSON.parse(localStorage.getItem("hotelDataSearch")).check_out,
    };
  },
  components: {
    // generalDetials,
    // attachment,
    // rate,
    Services,
    aboutHotel,
    RoomsOption,
  },
  name: "tab-detials",
  props: {
    hotelData: Object,
    ScrollUp: Function,
    pageParams: Number
  },
  mounted() {
    console.log(this.hotelData);
    this.tabMenuOffsetTop = this.$refs.tabMenu.offsetTop;
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapState(organizationSettings, ["whatsapp"]),
    whatsappLink() {
      const message =
          `اريد حجز ${this.rooms} غرفة\n` +
          `تاريخ الدخول: ${this.checkIn}\n` +
          `تاريخ الخروج: ${this.checkOut}\n` +
          `عدد البالغين: ${this.adults}\n` +
          `عدد الأطفال: ${this.childrens}`;
      return `https://wa.me/${this.whatsapp}?text=${encodeURIComponent(message)}`;
    },
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isTabFixed = window.scrollY > this.tabMenuOffsetTop;
    },
    checkRooms(rooms) {
      if (rooms.length == 0) {
        this.noRooms = true;
      }
    }
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_tabs.scss";
</style> -->
