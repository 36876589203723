<template>
  <div class="selects row w-100 mb-0">
    <div class="col-xl-8 col-lg-8 col-md-6 col-12">
      <div
          class="row position-relative mb-3"
          v-for="(airport, airportIndex) in allAirports"
          :key="airportIndex"
      >
        <div class="col-xl-8 col-lg-8 col-md-7 col-12">
          <div class="d-flex align-items-center position-relative multi_trips_inputs">
            <img
                src="@/assets/media/image/icon/launchFlight.svg"
                class="image"
                alt="launchFlight"
            />
            <div class="form-group w-100">
              <img src="@/assets/media/image/icon/plane-arrive.svg" alt="plane_image" class="plane_image" />
              <input
                  type="search"
                  class="form-control"
                  :placeholder="$t('Departure airport')"
                  v-model="airport.lunchTitle"
                  @keyup="debouncedSearchLunch($event, airportIndex)"
                  @focus="airport.showLaunch = true"
                  @focusout="handleFocusOutLaunch()"
              />
              <div
                  class="resultSearch"
                  v-if="airport.showLaunch && airport.lunchArr.length"
              >
                <h6 class="head_search">{{ $t("Arrival airport") }}</h6>
                <ul class="hotels">
                  <li
                      v-for="(stop, index) in airport.lunchArr"
                      :key="index"
                      @click="
                      sendStopName(
                        stop.title == null ? '' : stop.title,
                        stop.code,
                        airportIndex
                      )
                    "
                  >
                    <div class="hotel_image">
                      {{ stop.code == null ? "" : stop.code }}
                    </div>
                    <div class="texts">
                      <p class="title">
                        {{ stop.title == null ? "" : stop.title }}
                      </p>
                      <p class="country">
                        {{ stop.country == null ? "" : stop.country }} -
                        {{ stop.city_title == null ? "" : stop.city_title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group w-100">
              <img src="@/assets/media/image/icon/download.svg" alt="plane_image" class="plane_image" />
              <input
                  type="search"
                  class="form-control"
                  :placeholder="$t('Arrival airport')"
                  v-model="airport.returnTitle"
                  @keyup="debouncedSearchReturn($event, airportIndex)"
                  @focus="airport.showReturn = true"
                  @focusout="handleFocusOutReturn"
              />

              <div
                  class="resultSearch"
                  v-if="
                  airport.showReturn && airport.returnArr.length
                "
              >
                <h6 class="head_search">{{ $t("Arrival airport") }}</h6>
                <ul class="hotels">
                  <li
                      v-for="(stop, index) in airport.returnArr"
                      :key="index"
                      @click="
                      sendreturnName(
                        stop.title == null ? '' : stop.title,
                        stop.code,
                        airportIndex
                      )
                    "
                  >
                    <div class="hotel_image">
                      {{ stop.code == null ? "" : stop.code }}
                    </div>
                    <div class="texts">
                      <p class="title">
                        {{ stop.title == null ? "" : stop.title }}
                      </p>
                      <p class="country">
                        {{ stop.country == null ? "" : stop.country }} -
                        {{ stop.city_title == null ? "" : stop.city_title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
            :class="{
            'col-xl-4 col-lg-4 col-md-4 col-12': airportIndex == 0,
            'col-xl-4 col-lg-4 col-md-3 col-12': airportIndex != 0,
          }"
        >
          <div class="form-group w-100">
            <Datepicker
                v-model="airport.date"
                :lang="lang"
                class="Datepicker"
                :placeholder="$t('date')"
                :disabled-dates="{ to: new Date() }"
                icon="calendar"
                iconColor="white"
            />
<!--            <Datepicker-->
<!--                v-model="airport.date"-->
<!--                :lang="lang"-->
<!--                class="Datepicker"-->
<!--                :placeholder="$t('date')"-->
<!--                :disabled-dates="{ to: new Date() }"-->
<!--                iconColor="white"-->
<!--            />-->
          </div>
        </div>
        <button
            class="btn remove-btn"
            @click="remove_airport(airportIndex)"
            v-if="airportIndex != 0"
            :style="
            $root.$i18n.locale == 'ar'
              ? 'left: -2.5rem !important;'
              : 'right: -2.5rem !important;'
          "
        >
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-5 col-sm-12">
      <div class="form-group">
        <div class="dropdown w-100">
          <button
              type="button"
              class="btn btn-primary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
          >
            {{ $t("Adults") }} {{ adults }} - {{ $t("childrens") }}
            {{ childrens }} - {{ $t("babies") }} {{ babies }}
          </button>
          <div class="dropdown-menu">
            <div class="list">
              <span>{{ $t("Adults") }}</span>
              <div class="number">
                <button
                    class="btn minus"
                    :disabled="adults <= 1"
                    @click="minusQuantityAdults()"
                >
                  <i class="fa-solid fa-minus"></i>
                </button>
                <p class="count">{{ adults }}</p>
                <button class="btn add" @click="addQuantityAdults()">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="list">
              <span class="d-flex align-items-center"
              >{{ $t("childrens") }}
                <span class="mx-2" style="font-size: 0.6rem"
                >({{ $t("policy children") }})</span
                ></span
              >
              <div class="number">
                <button
                    class="btn minus"
                    :disabled="childrens <= 0"
                    @click="minusQuantityChildrens()"
                >
                  <i class="fa-solid fa-minus"></i>
                </button>
                <!-- <input type="text" class="count form-control" v-model=""> -->
                <p class="count">{{ childrens }}</p>
                <button class="btn add" @click="addQuantityChildrens()">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="list">
              <span class="d-flex align-items-center"
              >{{ $t("babies") }}
                <span class="mx-2" style="font-size: 0.6rem"
                >({{ $t("policy babies") }})</span
                ></span
              >
              <div class="number">
                <button
                    class="btn minus"
                    :disabled="babies <= 0"
                    @click="minusQuantityBabies()"
                >
                  <i class="fa-solid fa-minus"></i>
                </button>
                <!-- <input type="text" class="count form-control" v-model=""> -->
                <p class="count">{{ babies }}</p>
                <button class="btn add" @click="addQuantityBabies()">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-1 col-md-5 col-sm-12">
      <div class="form-group w-100">
        <Multiselect
            v-model="cabin_code"
            :options="cabin_codes.map((cabin_code) => cabin_code.id)"
            :custom-label="(opt) => cabin_codes.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('cabin_codes')"
            select-label=""
            :hide-selected="true"
            aria-owns="listbox-null"
            aria-controls="listbox-null"
            aria-haspopup="listbox"
            aria-expanded="false"
            aria-label="cabin_code"
        >
        </Multiselect>
      </div>
    </div>

    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-12">
      <button class="btn SecondaryButton w-100" @click="FetchFlights()">
        {{ $t("search_now") }}
      </button>
    </div>
  </div>

  <button
      class="btn add-flight"
      @click="add_airport"
      v-if="allAirports.length < 6"
  >
    <i class="fa-solid fa-plus"></i>
    {{ $t("add_multi_flight") }}
  </button>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment";
import Datepicker from "vuejs3-datepicker";

import axios from "axios";

export default {
  // computed: {
  //   buttonStyle() {
  //     return this.$i18n.locale == "ar"
  //         ? {left: "-2.5rem"}
  //         : {right: "-2.5rem"};
  //   },
  // },
  components: {
    Datepicker,
    Multiselect,
  },
  data() {
    return {
      lang:
          localStorage.getItem("lang") == "ar" ||
          localStorage.getItem("lang") == undefined
              ? "ar"
              : "en",
      companies: [],
      companyData: "",
      dateValue: [new Date(), ""],
      companyId: "",
      minSearc: 1,
      airports: [
        {
          date: new Date()
        }
      ],
      launchAirport: {},
      depAirport: {},
      show: false,
      cabin_codes: [
        {
          id: 1,
          title: this.$t("First Class"),
        },
        {
          id: 2,
          title: this.$t("Business Class"),
        },
        {
          id: 3,
          title: this.$t("Economy Standard"),
        },
        {
          id: 4,
          title: this.$t("Premium Economy"),
        },
        {
          id: 5,
          title: this.$t("Economy"),
        },
      ],
      tableRows: [],

      allAirports: [
        {
          lunchTitle: "",
          lunchId: "",
          lunchArr: [],
          returnTitle: "",
          returnId: "",
          returnArr: [],
        },
      ],
      cabin_code:
          JSON.parse(localStorage.getItem("flightData")) == null
              ? 5
              : JSON.parse(localStorage.getItem("flightData")).cabin_code,
      adults: 1,
      childrens: 0,
      babies: 0,
      offers: [],
      metadata: {},
      paxs: [],
      debounceTimeout: null,
      debounceTimeoutReturn: null,
      ip_address: null,
      showReturn: false,
      showLaunch: false,
      airportsData: []
    };
  },
  methods: {
    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // babies Minus && Add
    minusQuantityBabies() {
      if (this.babies > 0) {
        this.babies -= 1;
      }
    },
    addQuantityBabies() {
      this.babies += 1;
    },

    async getIP() {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        this.ip_address = await response.json();
        // console.log("ip_address", this.ip_address)
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    },

    // Debounce function with character length check
    debouncedSearchLunch(event, index) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        if (event.target.value.length <= 2) {
          return; // Exit if the input length is 2 or less
        }
        this.searchLunchAirport(event, index);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },
    handleFocusOutLaunch() {
      setTimeout(() => {
        this.allAirports.forEach((item) => {
          item.showLaunch = false;
        })
      }, 100); // Delay to allow click event to finish
    },

    handleFocusOutReturn() {
      setTimeout(() => {
        this.allAirports.forEach((item) => {
          item.showReturn = false;
        })
      }, 100); // Delay to allow click event to finish
    },

    searchLunchAirport(event, index) {
      const formData = new FormData();
      if (event) {
        formData.append("title", event.target.value);
      }
      if (!event?.target?.value?.length) {
        formData.append("ip_address", this.ip_address.ip);
      }
      axios
          .post(`/search_home_airports`, formData)
          .then(({data}) => {
            const indexDefault = index ? index : 0;
            this.allAirports[indexDefault].lunchArr = data.data;
            this.airportsData = data.data;
            this.allAirports[index].showlaunch = true;
            // console.log("testtt", data.data)
          })
          .catch((err) => console.log(err));
    },

    // Debounce function with character length check
    debouncedSearchReturn(event, index) {
      if (this.debounceTimeoutReturn) {
        clearTimeout(this.debounceTimeoutReturn);
      }

      this.debounceTimeoutReturn = setTimeout(() => {
        if (event.target.value.length <= 2) {
          return; // Exit if the input length is 2 or less
        }
        this.searchReturnAirport(event, index);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },

    searchReturnAirport(event, index) {
      const formData = new FormData();
      if (event) {
        formData.append("title", event.target.value);
      }
      if (!event?.target?.value?.length) {
        formData.append("ip_address", this.ip_address.ip);
      }
      axios
          .post(`/search_home_airports`, formData)
          .then(({data}) => {
            const indexDefault = index ? index : 0;
            this.allAirports[indexDefault].returnArr = data.data;
            this.allAirports[index].showReturn = true;
          })
          .catch((err) => console.log(err));
    },

    sendStopName(stopTitle, stop_id, airportIndex) {
      this.allAirports[airportIndex].lunchId = stop_id;
      this.allAirports[airportIndex].lunchTitle = stopTitle;
      // this.allAirports[airportIndex].lunchArr = [];
    },

    sendreturnName(stopTitle, stop_id, airportIndex) {
      this.allAirports[airportIndex].returnId = stop_id;
      this.allAirports[airportIndex].returnTitle = stopTitle;
      // this.allAirports[airportIndex].returnArr = [];
    },

    add_airport() {
      this.allAirports.push({
        lunchTitle: "",
        lunchId: "",
        lunchArr: this.airportsData,
        returnTitle: "",
        returnId: "",
        returnArr: this.airportsData,
        date: new Date(),
        showLaunch: false,
        showReturn: false,
      });
    },

    remove_airport(index) {
      this.allAirports.splice(index, 1);
    },

    // fetch flights
    async FetchFlights() {
      // Define your function logic here
      let pax = [];
      if (this.adults > 0) {
        pax.push({
          type: "ADT",
          count: this.adults,
        });
      }
      if (this.childrens > 0) {
        pax.push({
          type: "CHD",
          count: this.childrens,
        });
      }
      if (this.babies > 0) {
        pax.push({
          type: "INF",
          count: this.babies,
        });
      }
      // console.log(pax)

      let flight = [];
      flight = this.allAirports.map((item) => {
        return {
          from: item.lunchId == null ? null : item.lunchId,
          to: item.returnId == null ? null : item.returnId,
          date:
              moment(item.date).format("YYYY-MM-DD") == [new Date(), ""]
                  ? [new Date(), ""]
                  : moment(item.date).format("YYYY-MM-DD"),
        };
      });

      const flightData = {
        status: "multi_city",
        trips: flight,
        paxs: pax,
        cabin_code: this.cabin_code,
      };
      localStorage.setItem("flightData", JSON.stringify(flightData));
      this.$router.push("/flight-search");
      // this.$emit("runFlight");
      // console.log(this.tableRows, "tableRows");
    },
  },
  async created() {
    await this.getIP();
    await this.searchLunchAirport();
    await this.searchReturnAirport()
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
