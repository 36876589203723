<template>
  <!-- <Header /> -->
  <section class="hotel_search">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="filter_title">
            <h2>{{ $t("result_search") }}:</h2>
            <div class="filter_data">
              <span v-if="hotelData.city_title">
                {{ hotelData.city_title }}
              </span>
              <span v-if="hotelData.no_of_rooms">
                , {{ hotelData.no_of_rooms.length }} {{ $t("the room") }}
              </span>
              <span v-if="hotelData.no_of_rooms.adults">
                , {{ hotelData.no_of_rooms.adults }} {{ $t("adults") }}
              </span>
              <span v-if="hotelData.no_of_rooms.childrens"
                >, {{ hotelData.no_of_rooms.childrens }} {{ $t("childrens") }}
              </span>
              <span v-if="hotelData.check_in_date"
                >, {{ $t("date of arrival") }}: {{ hotelData.check_in_date }}
              </span>
              <span v-if="hotelData.check_out_date"
                >, {{ $t("Departure Date") }}: {{ hotelData.check_out_date }}
              </span>
            </div>
            <div class="button_collection">
              <!-- <button class="edit btn" @click="edit_form = 'true'">
                <i class="fa-solid fa-pen-to-square"></i>
              </button> -->
              <router-link to="/hotels" class="delete btn" aria-label="delete">
                <i class="fa-solid fa-trash"></i>
              </router-link>
            </div>
          </div>

          <form>
            <!-- <h5>{{$t("edit_search")}}</h5> -->
            <div class="selects row">
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <input
                    type="search"
                    class="form-control"
                    :placeholder="$t('choose_hotel')"
                    v-model="searchValue"
                    @keyup="searchHotels"
                  />
                  <div
                    class="resultSearch"
                    v-if="
                      searchValue.length > 2 &&
                      hotelsSearch.length &&
                      citiesSearch.length
                    "
                  >
                    <h6 class="head_search">{{ $t("The Cities") }}</h6>
                    <ul class="cities">
                      <li
                        v-for="(city, index) in citiesSearch"
                        :key="index"
                        @click="sendCityId(city.title, city.id)"
                      >
                        <i class="fa-solid fa-location-dot"></i>
                        <div class="texts">
                          <p class="title">{{ city.title }}</p>
                          <p class="country">{{ city.country_title }}</p>
                        </div>
                      </li>
                    </ul>
<!--                    <h6 class="head_search">{{ $t("Hotels") }}</h6>-->
<!--                    <ul class="hotels">-->
<!--                      <li-->
<!--                        v-for="(hotel, index) in hotelsSearch"-->
<!--                        :key="index"-->
<!--                        @click="-->
<!--                          sendHotelName(-->
<!--                            hotel.title,-->
<!--                            hotel.hotel_code,-->
<!--                            hotel.city_id-->
<!--                          )-->
<!--                        "-->
<!--                      >-->
<!--                        <img-->
<!--                          :src="hotel.image"-->
<!--                          class="hotel_image"-->
<!--                          :alt="hotel.title"-->
<!--                        />-->
<!--                        <div class="texts">-->
<!--                          <p class="title">{{ hotel.title }}</p>-->
<!--                          <p class="country">-->
<!--                            {{ hotel.country_title }} , {{ hotel.city_title }}-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                    </ul>-->
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-2">
                <div class="form-group">
                  <Multiselect
                    v-model="nationalityValue"
                    :options="nationalities"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose_nationality')"
                    select-label=""
                    :hide-selected="true"
                    @keyup="searchNationality"
                  >
                  </Multiselect>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <div class="card flex justify-content-center">
                    <Calendar
                      v-model="selectedDate"
                      selectionMode="range"
                      dateFormat="dd/mm/yy"
                      :manualInput="true"
                      ariaLabel="Choose an dates"
                      showIcon
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-2">
                <div class="form-group">
                  <div class="dropdown">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                    >
                      {{ $t("rooms") }} {{ rooms.length }} - {{ $t("Adults") }}
                      {{ adults }} - {{ $t("childrens") }} {{ childrens }}
                    </button>
                    <div class="dropdown-menu">
                      <div class="list">
                        <span>{{ $t("Adults") }}</span>
                        <div class="number">
                          <button
                            type="button"
                            class="btn minus"
                            @click="minusQuantityAdults()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ adults }}</p>
                          <button
                            type="button"
                            class="btn add"
                            @click="addQuantityAdults()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="list">
                        <span>{{ $t("childrens") }}</span>
                        <div class="number">
                          <button
                            type="button"
                            class="btn minus"
                            @click="minusQuantityChildrens()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ childrens }}</p>
                          <button
                            type="button"
                            class="btn add"
                            @click="addQuantityChildrens()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="list">
                        <span>{{ $t("rooms") }}</span>
                        <div class="number">
                          <button
                            type="button"
                            class="btn minus"
                            @click="minusQuantityRooms()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ rooms }}</p>
                          <button
                            type="button"
                            class="btn add"
                            @click="addQuantityRooms()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12">
                <button
                  type="button"
                  @click="edit_search()"
                  class="btn PrimaryButton search"
                >
                  {{ $t("edit search") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <section class="hotel_search">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-12">
            <div class="address_hotel">
              <div class="map_image">
                <img src="@/assets/media/image/map.png" alt="" />
                <button class="btn PrimaryButton" @click="showMap">
                  {{ $t("show_map") }}
                </button>
              </div>
              <!-- <div class="data">
        <a
          class="show_map"
          :href="`https://www.google.com/maps?q=${this.hotel.lat},${this.hotel.long}&z=${this.zoom}`"
          target="_blank"
          >{{ $t("show_map") }}</a
        >
      </div> -->
              <!-- <div class="map">
        <GMapMap
          :center="{
            lat: parseFloat(this.hotel.lat),
            lng: parseFloat(this.hotel.long),
          }"
          :zoom="zoom"
          :api-key="googleMapsApiKey"
        >
          <GMapMarker
            :position="{ lat: parseFloat(lat), lng: parseFloat(long) }"
            :icon="customIcon"
          />
        </GMapMap>
      </div> -->
            </div>
            <form>
              <div class="filter_sec">
                <div class="offcanvas-body">
                  <h4 class="text-center">{{ $t("clear_result") }}</h4>
                  <form @submit.prevent="fetchHotel()">
                    <div class="prices mt-4">
                      <h5>{{ $t("prices") }}</h5>
                      <ul>
                        <vue-slider
                          v-model="priceValue"
                          :min="minValue"
                          :max="maxValue"
                          :step="stepValue"
                          :lazy="true"
                          direction="rtl"
                          :tooltip="'always'"
                          :tooltip-placement="['bottom', 'bottom']"
                        ></vue-slider>
                      </ul>
                    </div>

                    <div class="hotel_stars mt-4">
                      <h5>{{ $t("hotel stars") }}</h5>
                      <ul>
                        <li
                          class="row-data"
                          v-for="star in [5, 4, 3, 2, 1]"
                          :key="star"
                        >
                          <div class="form-group">
                            <input
                              type="radio"
                              :id="'star-' + star"
                              class="form-check"
                              v-model="selectedStars"
                              :value="star"
                            />
                            <label :for="'star-' + star" class="form-label">
                              {{ star }} {{ $t("stars") }}
                              <span>
                                <template v-for="i in 5" :key="i">
                                  <i
                                    :class="[
                                      i <= star
                                        ? 'fa-solid fa-star'
                                        : 'fa-regular fa-star',
                                    ]"
                                  ></i>
                                </template>
                              </span>
                            </label>
                          </div>
                          <div class="number"></div>
                        </li>
                      </ul>
                    </div>

                    <div class="adds mt-4">
                      <h5>{{ $t("adds") }}</h5>
                      <ul>
                        <li class="row-data">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              name="payment"
                              class="form-check"
                            />
                            <label class="form-label">{{
                              $t("breakfast")
                            }}</label>
                          </div>
                          <!-- <div class="number">123</div> -->
                        </li>
                        <li class="row-data">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              name="payment"
                              class="form-check"
                              v-model="rate"
                            />
                            <label class="form-label">{{
                              $t("Breakfast and lunch included")
                            }}</label>
                          </div>
                          <div class="number"></div>
                        </li>
                      </ul>
                    </div>

                    <button class="btn results w-100 mt-4">
                      {{ $t("show") }} {{ fetchedHotels.length }}
                      {{ $t("from") }}
                      {{ $t("results") }}
                    </button>
                  </form>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-9 col-12">
            <div class="container">
              <div class="row">
                <div class="col-lg-2 col-12">
                  <h5 class="text-center mb-2">
                    {{ fetchedHotels.length }} {{ $t("Hotels") }}
                  </h5>
                </div>
                <div class="col-12 col-lg-5">
                  <form class="hotelSearch">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="text"
                      class="form-control"
                      v-model="hotel_name"
                      @keyup="fetchHotel()"
                      :placeholder="$t('search with hotel name')"
                    />
                  </form>
                </div>
                <div class="col-lg-5 col-12">
                  <ul class="filter-list">
                    <li>
                      <input
                        type="checkbox"
                        id="highestRating"
                        v-model="order_rate"
                        @change="fetchHotel()"
                      />
                      <label class="mx-2" for="highestRating">{{
                        $t("Highest rating")
                      }}</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="lowestPrice"
                        v-model="order_price"
                        @change="fetchHotel()"
                      />
                      <label class="mx-2" for="lowestPrice">{{
                        $t("Lowest price")
                      }}</label>
                    </li>
                    <li>
                      {{ $t("Close to you") }}
                      <i class="fa-solid fa-circle-exclamation"></i>
                      <div class="filter_popup">
                        <h5>
                          {{ $t("Find out with us the hotels near you..!") }}
                        </h5>
                        <p>
                          {{
                            $t(
                              "This text is an example of text that can be replaced in the same space. It has been done Generate this text from the Arabic text generator, where you can generate such as This text or many other texts added to increase the number"
                            )
                          }}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row">
                <HotelCard
                  v-for="hotel in fetchedHotels"
                  :key="hotel.id"
                  :hotel="hotel"
                  :session_id="session_id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>

  <!-- <hotelSearch
    @hotel-data-fetched="handleFetchedHotelData"
    v-if="this.fetchedHotels.length > 0"
    :fetchedHotels="fetchedHotels"
    :session_id="session_id"
  /> -->
  <div v-if="loading" class="loading">
    <Loading />
  </div>
</template>

<script>
// import Header from "@/components/home/Header.vue";
// import hotelSearch from "@/components/hotel/hotelSearch.vue";
import axios from "axios";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import moment from "moment";
import HotelCard from "@/components/hotel/HotelCard.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Calendar from "primevue/calendar";

export default {
  data() {
    return {
      hotelData: {},
      fetchedHotels: [],
      loading: false,
      session_id: "",
      priceValue: [0, 500],
      minValue: 0,
      maxValue: 5000,
      stepValue: 10,
      selectedStars: "",
      order_rate: 1,
      order_price: 1,
      countryValue: "",
      searchValue:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? ""
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.hotel_name ==
            null
          ? JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_title
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.hotel_name,
      hotel_name: "",
      cityValue: [],
      nationalities: [],
      nationalityValue:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? ""
          : JSON.parse(localStorage.getItem("hotelDataSearch"))
              ?.guest_nationality,
      hotelsSearch: [],
      citiesSearch: [],
      cityId:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? ""
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_id,
      cityTitle:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? ""
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_title,
      hotelValue: "",
      selectedDate:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? null
          : [
              JSON.parse(localStorage.getItem("hotelDataSearch"))
                ?.check_in_date,
              JSON.parse(localStorage.getItem("hotelDataSearch"))
                ?.check_out_date,
            ], //[new Date(), ""],
      adults:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? 1
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.adults,
      childrens:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? 1
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.childrens,
      rooms:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? 1
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.no_of_rooms,
      searchValueLaunch: "",
      searchValueReturn: "",
      edit_form: false,
    };
  },
  components: {
    // Header,
    // hotelSearch,
    Loading,
    HotelCard,
    VueSlider,
    Multiselect,
    Calendar,
  },
  methods: {
    fetchHotel() {
      this.loading = true;

      this.hotelData = JSON.parse(localStorage.getItem("hotelDataSearch"));

      let fetchedHotel = {
        adults: this.hotelData == null ? 1 : this.hotelData.adults,
        check_in_date:
          this.hotelData == null
            ? moment(new Date()).format("YYYY-MM-DD")
            : this.hotelData.check_in_date,
        check_out_date:
          this.hotelData == null
            ? moment(new Date()).format("YYYY-MM-DD")
            : this.hotelData.check_out_date,
        childrens: this.hotelData == null ? 0 : this.hotelData.childrens,
        city_id: this.hotelData == null ? "" : this.hotelData.city_id,
        city_title: this.hotelData == null ? "" : this.hotelData.city_title,
        guest_nationality:
          this.hotelData == null ? "" : this.hotelData.guest_nationality,
        hotel_name: this.hotel_name,
        is_near_by_search_allowed: false,
        no_of_rooms: this.hotelData == null ? 1 : this.hotelData.no_of_rooms,
        from_price: this.priceValue[0] == null ? "" : this.priceValue[0],
        to_price: this.priceValue[1] == null ? "" : this.priceValue[1],
        order_rate: this.order_rate == 1 ? 1 : 2,
        order_price: this.order_price == 1 ? 1 : 2,
        rate: this.selectedStars == null ? "" : this.selectedStars,
      };

      axios
        .post("/fetch_home_hotels", fetchedHotel)
        .then(({ data }) => {
          this.loading = false;
          (this.fetchedHotels = data.data.data),
            (this.session_id = data.data.meta.session_id);
          localStorage.setItem("hotelDataSearch", JSON.stringify(fetchedHotel));
          // window.scrollTo({
          //   top: 800,
          //   behavior: 'smooth' // Add smooth scrolling behavior
          // });
        })
        .catch(({ error }) => {
          // console.log(error.data.message)
          Swal.fire({
            title: "",
            text: "please_try_again",
            icon: "error",
          });
          this.loading = false; //the loading ended
          return error;
        });
    },
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
    },
    searchHotels(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/search_home_hotels`, searchText)
        .then(
          (res) => (
            (this.hotelsSearch = res.data.data.hotels),
            (this.citiesSearch = res.data.data.cities)
          )
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
    },

    // fetch hotels
    sendHotelName(hotelName, hotelID, cityId) {
      this.hotelName = hotelName;
      this.hotelID = hotelID;
      this.cityTitle = null;
      this.searchValue = hotelName;
      this.cityId = cityId;
      this.citiesSearch.length = [];
      this.hotelsSearch = [];
      // console.log(this.hotelName)
      // console.log(this.hotelID)
    },
    sendCityId(cityTitle, cityId) {
      this.cityId = cityId;
      this.cityTitle = cityTitle;
      this.searchValue = cityTitle;
      this.hotelName = null;
      this.hotelID = null;
      this.citiesSearch = [];
      this.hotelsSearch = [];
      // console.log(this.cityTitle)
      // console.log(this.cityId)
    },
    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // Rooms Minus && Add
    minusQuantityRooms() {
      if (this.rooms > 1) {
        this.rooms -= 1;
      }
    },
    addQuantityRooms() {
      this.rooms += 1;
    },
    edit_search() {
      let fetchedHotel = {
        adults: this.adults,
        check_in_date: this.selectedDate[0],
        check_out_date: this.selectedDate[1],
        childrens: this.childrens,
        city_id: this.cityId,
        city_title: this.cityTitle,
        guest_nationality: this.nationalityValue,
        hotel_name: this.searchValue,
        is_near_by_search_allowed: false,
        no_of_rooms: this.rooms,
        from_price: this.priceValue[0] == null ? "" : this.priceValue[0],
        to_price: this.priceValue[1] == null ? "" : this.priceValue[1],
        order_rate: this.order_rate == 1 ? 1 : 2,
        order_price: this.order_price == 1 ? 1 : 2,
        rate: this.selectedStars == null ? "" : this.selectedStars,
      };

      axios
        .post("/fetch_home_hotels", fetchedHotel)
        .then(({ data }) => {
          this.loading = false;
          (this.fetchedHotels = data.data.data),
            (this.session_id = data.data.meta.session_id);
          localStorage.setItem("hotelDataSearch", JSON.stringify(fetchedHotel));
        })
        .catch(({ error }) => {
          // console.log(error.data.message);
          Swal.fire({
            title: "",
            text: error.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  mounted() {
    if (localStorage.getItem("hotelDataSearch")) {
      this.fetchHotel();
    }
  },
  created() {
    localStorage.removeItem("hotelDataSearch");
    localStorage.removeItem("hotelDataFilter");
    localStorage.removeItem("roomData");
    localStorage.removeItem("newBook");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotel_Details");
    localStorage.removeItem("roomPrice");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("hotelMeta");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("flightData");
    localStorage.removeItem("BaggageAllowance");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("packageData");
    sessionStorage.removeItem("searchData");
    sessionStorage.removeItem("packageData");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  // @import "@/components/home/_hotel.scss";
  // @import "@/components/home/_header.scss";
  @import "./_hotelSearch.scss";
</style> -->
