<template>
  <DeferredContent @load="fetchMyApplication">
  <section class="Application_sec" v-if="app_section_data?.google_play_url || app_section_data?.app_store_url">
    <div class="container">
      <div class="row align-content-center">
        <div class="col-12 col-lg-4 app_img">
          <img :src="app_section_data?.image" :alt="app_section_data?.title" loading="lazy" />
        </div>
        <div class="content col-12 col-lg-8">
          <div class="row align-items-center">
            <div class="col-12 col-lg-12">
              <div class="w-100">
                <h2 class="fw-bold text-white fs-2 mx-0 px-0 mb-3 text-wrap">
                  {{ app_section_data?.title }}
                </h2>
                <p class="fs-6 fw-light text-secondary text-grey">
                  {{ app_section_data?.subtitle }}
                </p>
                <div class="row align-items-center">
                  <div class="col-12">
                    <ul class="m-0 mt-2">
                      <li
                        class="d-flex align-items-center gap-3 mb-3"
                        v-for="(web_feature, index) in web_features"
                        :key="index"
                      >
                        <img src="@/assets/media/image/verify.png" alt="verify"  loading="lazy"/>
                        <div class="fs-6 fw-bold text-white mb-0">
                          <h5>
                            {{ web_feature.title }}
                          </h5>
                          <p v-html="web_feature.subtitle"></p>
                        </div>
                      </li>
                    </ul>
                    <div class="d-flex align-items-center gap-3 mt-3">
                      <a
                        v-if="app_section_data?.google_play_url"
                        :href="app_section_data?.google_play_url"
                        target="_blank"
                      >
                        <img src="@/assets/media/image/googleplay.png" alt="google play" loading="lazy" />
                      </a>
                      <a
                        v-if="app_section_data?.app_store_url"
                        :href="app_section_data?.app_store_url"
                        target="_blank"
                      >
                        <img src="@/assets/media/image/appstore.png"  alt="app store" loading="lazy" />
                      </a>
                    </div>
                  </div>
                  <!-- <div class="col-12 col-lg-6 d-flex justify-content-center mt-3">
                    <div v-if="qrCodeData" class="qrCodeData">
                      <img :src="qrCodeData" alt="QR Code" />
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </DeferredContent>
</template>

<script>
import axios from "axios";
import { organizationSettings } from "@/store/modules/organizationSettings";
import { mapState } from "pinia";
import QRCode from "qrcode-generator";
import DeferredContent from "primevue/deferredcontent";

export default {
  name: "ApplicationSec",
  components: {
    DeferredContent
  },
  data() {
    return {
      app_section_data: [],
      web_features: [],
      website_link: window.location.origin,
      text: window.location.origin,
      qrCodeData: null,
    };
  },
  methods: {
    async fetchMyApplication() {
      await axios.get("/app_sections").then(({ data }) => {
        this.app_section_data = data.data;
        // console.log(this.app_section_data);
      });
      await axios.get("/web_features").then(({ data }) => {
        this.web_features = data.data.data;
      });
    },
    generateQrCode() {
      const typeNumber = 0;
      const errorCorrectionLevel = "L";
      const qr = QRCode(typeNumber, errorCorrectionLevel);
      qr.addData(this.text);
      qr.make();
      this.qrCodeData = qr.createDataURL(4, 0);
    },
  },
  // async created() {
  //   await Promise.all([this.fetchMyApplication()]);
  // },
  mounted() {
    this.generateQrCode();
  },
  computed: {
    ...mapState(organizationSettings, ["data"]),
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "./_Application.scss";

</style> -->
