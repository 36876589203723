import {defineStore} from "pinia";
import axios from "axios";
// import { writeFileSync } from 'fs';


export const organizationSettings = defineStore("organizationSettings", {
    state: () => ({
        data: [],
        logo: null,
        defaultImage: "",
        title: "",
        description: "",
        email: "",
        address: "",
        city: "",
        country: "",
        mobliePhone: null,
        website_link: "",
        whatsapp: "",
        facebook: "",
        instagram: "",
        snapchat: "",
        youtube: "",
        twitter: "",
        tiktok: "",
        provider: [],
        loading: true,
        services: [],
        website_hotel_request_type: null,
        seo: [],
        primary_color: null
    }),
    actions: {
        async getSettings() {
            try {
                const response = await axios.get("/organization_settings_index");
                this.data = response.data.data;
                this.primary_color = response.data.data.primary_color
                this.website_link = response.data.data.website_link;
                this.whatsapp = response.data.data.whatsapp;
                this.facebook = response.data.data.facebook;
                this.instagram = response.data.data.instgram;
                this.snapchat = response.data.data.snapchat;
                this.youtube = response.data.data.youtube;
                this.twitter = response.data.data.twitter;
                this.tiktok = response.data.data.tiktok;
                this.title = response.data.data.title;
                this.address = response.data.data.address;
                this.country = response.data.data.country?.code;
                this.city = response.data.data.city?.title;
                this.description = response.data.data.mete_description;
                this.email = response.data.data.email;
                this.mobliePhone = response.data.data.phone;
                this.logo = response.data.data.logo;
                // console.log(this.phone, "phone")
                this.defaultImage = response.data.data.default_image;
                this.provider = response.data.data.hotel_providers;
                this.website_hotel_request_type = response.data.data.website_hotel_request_type;
                localStorage.setItem("providers", JSON.stringify(this.provider));
                localStorage.setItem("DefaultImage", this.defaultImage);
                // Update the page title
                const titleEl = document.querySelector("head title");
                if (titleEl) {
                    titleEl.textContent = response.data.data.meta_title + ' Website';
                } else {
                    console.warn("Title element not found!");
                }

                // Update the meta description content
                const descriptionEl = document.querySelector('meta[name="description"]');
                if (descriptionEl) {
                  descriptionEl.setAttribute('content', response?.data?.data.mete_description);
                  // console.log('Updated Description:', response?.data?.data.mete_description);
                  // console.log('descriptionEl', descriptionEl);
                } else {
                    // If the meta tag doesn't exist, create one
                    const newMeta = document.createElement('meta');
                    newMeta.name = 'description';
                    newMeta.content = response?.data?.data?.meta_description || "";
                    document.head.appendChild(newMeta);
                }

                // Update the favicon
                const favIcon = document.querySelector("link[rel~='icon']");
                if (favIcon) {
                  favIcon.href = this.defaultImage;
                } else {
                  console.warn("Favicon element not found!");
                }

                this.services = response?.data?.data?.services;
                // console.log(this.services, "this.services");
                // localStorage.setItem(", services", JSON.stringify(this.services));
            } catch (error) {
                this.loading = false;
                console.error("Error fetching organization settings:", error);
            } finally {
                this.loading = false;
            }
        },
        // async updateRobotsFile(content) {
        //     try {
        //         const response = await axios.post("/update-robots", { content });
        //         console.log("robots.txt updated:", response.data.message);
        //     } catch (error) {
        //         console.error("Error updating robots.txt:", error);
        //     }
        // },
    },
});
