<template>
  <section class="testi_sec mt-5">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h2 class="fw-bold fs-4 mx-0 px-0 mb-2">{{ $t("What did our customers say about us?") }}</h2>
      <img src="../../assets/media/image/stars.png" alt="stars" loading="lazy"/>
    </div>
    <swiper
        :breakpoints="{
        '0': {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }"
        :modules="modules"
        :navigation="true"
        :pagination="{
        clickable: true,
      }"
        :slidesPerView="3"
        :spaceBetween="30"
        class="mySwiper"
    >
      <swiper-slide v-for="(client, index) in client_opinion" :key="index">
        <testimonialCard :client="client"/>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import testimonialCard from "../testimonialCard.vue";
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

//   import './style.css';
// import required modules
import {Navigation, Pagination} from "swiper/modules";

import "swiper/css/navigation";

export default {
  name: "TestimonialsSec",
  components: {
    testimonialCard,
    Swiper,
    SwiperSlide,
  },
  props: ["client_opinion"],
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./_Testimonials.scss";
</style> -->
