<template>
  <section class="terms">
    <div class="container">
      <h2 class="mx-0 px-0 mb-2">{{ $t("terms") }}</h2>
      <p v-html="terms.description"></p>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "terms-home",
  data() {
    return {
      terms: [],
    };
  },
  methods: {
    fetchTerms() {
      const formData = new FormData();
      formData.append("for_invoice", 0);
      axios.post("/term", formData).then(({ data }) => {
        this.terms = data.data;

        // Set the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', this.$t("terms"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'description';
          newMeta.content = this.$t("terms");
          document.head.appendChild(newMeta);
        }
        // Set the meta title
        const metaTitle = document.querySelector('meta[name="title"]');
        if (metaTitle) {
          metaTitle.setAttribute('content', this.$t("terms"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'title';
          newMeta.content = this.$t("terms");
          document.head.appendChild(newMeta);
        }
        // Update the page title
        const titleEl = document.querySelector("head title");
        if (titleEl) {
          titleEl.textContent = this.$t("terms");
        } else {
          console.warn("Title element not found!");
        }
      });
    },
  },
  mounted() {
    this.fetchTerms();
  },
};
</script>

<style></style>
