<template>
  <!-- <Header /> -->
  <div v-if="loading" class="loading">
    <Loading/>
  </div>
  <section v-else>
    <div class="container-fluid hotel-search-new">
      <div class="row">
        <div class="col-12">
          <div class="filter_title pt-3">
            <h2>{{ $t("result_search") }}:</h2>
            <div class="filter_data">
              <span v-if="hotelInfo.city_name">
                {{ hotelInfo.city_name }}
              </span>
              <span v-if="hotelInfo.no_of_rooms">
                , {{ hotelInfo.no_of_rooms }} {{ $t("the room") }}
              </span>
              <span v-if="totalAdults">
                , {{ totalAdults }}
                {{ $t("adults") }}
              </span>
              <span v-if="totalChildrens"
              >, {{ totalChildrens }}
                {{ $t("childrens") }}
              </span>
              <span v-if="hotelInfo.check_in"
              >, {{ $t("date of arrival") }}: {{ hotelInfo.check_in }}
              </span>
              <span v-if="hotelInfo.check_out"
              >, {{ $t("Departure Date") }}: {{ hotelInfo.check_out }}
              </span>
            </div>
            <div class="button_collection">
              <!-- <button class="edit btn" @click="edit_form = 'true'">
                <i class="fa-solid fa-pen-to-square"></i>
              </button> -->
              <router-link class="delete btn" to="/hotels">
                <i class="fa-solid fa-trash"></i>
              </router-link>
            </div>
          </div>

          <form>
             <h5>{{$t("edit_search")}}</h5>
            <div class="selects row">
              <div class="col-12 col-md-6 col-lg-3 my-2">
                <div class="form-group">
                  <input
                      v-model="searchValue"
                      :placeholder="$t('choose_city')"
                      class="form-control"
                      type="search"
                      @input="debouncedSearchHotels"
                  />
                  <div
                      v-if="
                      searchValue == null
                        ? null
                        : searchValue.length > 2 &&
                          (citiesSearch.length || hotelsSearch.length)
                    "
                      class="resultSearch"
                  >
                    <h6 v-if="citiesSearch.length" class="head_search">
                      {{ $t("The Cities") }}
                    </h6>
                    <ul v-if="citiesSearch.length" class="cities">
                      <li
                          v-for="(city, index) in citiesSearch"
                          :key="index"
                          @click="
                          sendCityId(
                            city.title,
                            city.id,
                            city.country_code,
                            city.country_title
                          )
                        "
                      >
                        <i class="fa-solid fa-location-dot"></i>
                        <div class="texts">
                          <p class="title">{{ city.title }}</p>
                          <p class="country">{{ city.country_title }}</p>
                        </div>
                      </li>
                    </ul>
  <!--                  <h6 v-if="hotelsSearch.length" class="head_search">-->
  <!--                    {{ $t("Hotels") }}-->
  <!--                  </h6>-->
  <!--                  <ul v-if="hotelsSearch.length" class="hotels">-->
  <!--                    <li-->
  <!--                        v-for="(hotel, index) in hotelsSearch"-->
  <!--                        :key="index"-->
  <!--                        @click="-->
  <!--                        sendHotelName(-->
  <!--                          hotel.title,-->
  <!--                          hotel.hotel_code,-->
  <!--                          hotel.city_id,-->
  <!--                          hotel.country_code,-->
  <!--                          hotel.country_title-->
  <!--                        )-->
  <!--                      "-->
  <!--                    >-->
  <!--                      <img-->
  <!--                          :alt="hotel.title"-->
  <!--                          :src="hotel.image"-->
  <!--                          class="hotel_image"-->
  <!--                      />-->
  <!--                      <div class="texts">-->
  <!--                        <p class="title">{{ hotel.title }}</p>-->
  <!--                        <p class="country">-->
  <!--                          {{ hotel.country_title }} , {{ hotel.city_title }}-->
  <!--                        </p>-->
  <!--                      </div>-->
  <!--                    </li>-->
  <!--                  </ul>-->
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-2 my-2">
                <div class="form-group">
                  <Multiselect
                      v-model="nationalityValue"
                      :clear-on-select="true"
                      :hide-selected="true"
                      :options="nationalities"
                      :placeholder="$t('choose_nationality')"
                      label="title"
                      select-label=""
                      track-by="id"
                      @keyup="searchNationality"
                  >
                  </Multiselect>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3 my-2">
                <div class="form-group">
                  <div class="card flex justify-content-center">
                    <Calendar
                        v-model="selectedDate"
                        :manualInput="true"
                        dateFormat="dd/mm/yy"
                        selectionMode="range"
                        showIcon
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-2 my-2">
                <div class="form-group">
                  <div class="dropdown">
                    <button
                        aria-expanded="false"
                        class="btn dropdown-toggle SecondButton"
                        data-bs-auto-close="outside"
                        data-bs-toggle="dropdown"
                        type="button"
                    >
                      {{ $t("rooms") }} {{ hotelInfo.no_of_rooms }} -
                      {{ $t("Adults") }} {{ adults }} - {{ $t("childrens") }}
                      {{ childrens }}
                    </button>
                    <div class="dropdown-menu">
                      <div class="list">
                        <span>{{ $t("Adults") }} </span>
                        <div class="number">
                          <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantityAdults()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ adults }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityAdults()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="list">
                        <span>{{ $t("childrens") }}</span>
                        <div class="number">
                          <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantityChildrens()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ childrens }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityChildrens()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="list">
                        <span>{{ $t("rooms") }}</span>
                        <div class="number">
                          <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantityRooms()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ hotelInfo.no_of_rooms }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityRooms()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12 my-2">
                <button
                    class="btn PrimaryButton search"
                    type="button"
                    @click="edit_search()"
                >
                  {{ $t("edit search") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="hotel_search">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-12" v-if="fetchedHotels.length">
          <!--          <div class="address_hotel">-->
          <!--            <div class="map">-->
          <!--              <GoogleMap-->
          <!--                :center="{-->
          <!--                  lat: parseFloat(this.fetchedHotels.map((hotel) => hotel.lat)),-->
          <!--                  lng: parseFloat(-->
          <!--                    this.fetchedHotels.map((hotel) => hotel.long)-->
          <!--                  ),-->
          <!--                }"-->
          <!--                :zoom="zoom"-->
          <!--                :api-key="googleMapsApiKey"-->
          <!--              >-->
          <!--                <Marker-->
          <!--                  :position="{ lat: parseFloat(lat), lng: parseFloat(long) }"-->
          <!--                  :icon="customIcon"-->
          <!--                />-->
          <!--              </GoogleMap> -->
          <!--            </div>-->
          <!--          </div>-->
          <form>
            <div class="filter_sec">
              <div class="offcanvas-body">
                <h4 class="text-center">{{ $t("clear_result") }}</h4>
                <form>
                  <div class="prices mt-4 mb-4">
                    <h5>{{ $t("prices") }}</h5>
                    <ul>
                      <vue-slider
                          v-model="priceValue"
                          :lazy="true"
                          :max="maxValue"
                          :min="minValue"
                          :step="stepValue"
                          :tooltip="'always'"
                          :tooltip-placement="['bottom', 'bottom']"
                          direction="rtl"
                          @click="filter_hotels"
                      ></vue-slider>
                    </ul>
                  </div>

                  <div class="hotel_stars mt-5">
                    <h5 class="mb-2">{{ $t("hotel stars") }}</h5>
                    <ul>
                      <li
                          v-for="star in [5, 4, 3, 2, 1]"
                          :key="star"
                          class="row-data"
                      >
                        <div class="form-group mb-0">
                          <input
                              :id="'star-' + star"
                              v-model="selectedStars"
                              :value="star"
                              class="form-check"
                              type="radio"
                              @change="filter_hotels"
                          />
                          <label :for="'star-' + star" class="form-label">
                            {{
                              star === 1 ? $t("star") : star === 2 ? $t("two_stars") : star === 3 ? $t("three_stars") : star === 4 ? $t("four_stars") : $t("five_stars")
                            }}
                            <!--                            {{ star }} {{ $t("stars") }}-->
                            <span>
                              <template v-for="i in 5" :key="i">
                                <i
                                    :class="[
                                    i <= star
                                      ? 'fa-solid fa-star'
                                      : 'fa-regular fa-star',
                                  ]"
                                ></i>
                              </template>
                            </span>
                          </label>
                        </div>
                        <div class="number"></div>
                      </li>
                    </ul>
                  </div>
                  <button
                      class="btn results w-100 mt-4"
                      type="button"
                      @click="filter_hotels"
                  >
                    {{ $t("show") }}
                    {{
                      priceFetched == false
                          ? fetchedHotels.length
                          : data_filtered == true
                              ? copy_hotels.length
                              : hotelList.length
                    }}
                    {{ $t("from") }}
                    {{ $t("results") }}
                  </button>
                </form>
              </div>
            </div>
          </form>
        </div>
        <div :class="fetchedHotels.length ? 'col-lg-9 col-12' : 'col-12'">
          <div class="container-fluid mx-0 px-0">
            <div class="row mb-3" v-if="fetchedHotels.length">
              <div class="col-lg-2 col-12">
                <h5 class="text-center mb-2">
                  {{
                    priceFetched == false
                        ? fetchedHotels.length
                        : data_filtered == true
                            ? copy_hotels.length
                            : hotelList.length
                  }}
                  {{ $t("Hotels") }}
                </h5>
              </div>
              <div class="col-12 col-lg-5">
                <form class="hotelSearch">
                  <i class="fa-solid fa-magnifying-glass"></i>
                  <input
                      v-model="hotel_name"
                      :placeholder="$t('search with hotel name')"
                      class="form-control"
                      type="text"
                      @keyup="filter_hotels"
                  />
                  <!-- @keyup="edit_search()" -->
                </form>
              </div>
              <div class="col-lg-5 col-12">
                <ul class="filter-list">
                  <li>
                    <input
                        id="highestRating"
                        v-model="order_rate"
                        type="checkbox"
                        @change="filter_hotels"
                    />
                    <label class="mx-2" for="highestRating">{{
                        $t("Highest rating")
                      }}</label>
                  </li>
                  <li>
                    <input
                        id="lowestPrice"
                        v-model="order_price"
                        type="checkbox"
                        @change="filter_hotels"
                    />
                    <label class="mx-2" for="lowestPrice">{{
                        $t("Lowest price")
                      }}</label>
                  </li>
                  <li>
                    {{ $t("Close to you") }}
                    <i class="fa-solid fa-circle-exclamation"></i>
                    <div class="filter_popup">
                      <h5>
                        {{ $t("Find out with us the hotels near you..!") }}
                      </h5>
                      <p>
                        {{
                          $t(
                              "This text is an example of text that can be replaced in the same space. It has been done Generate this text from the Arabic text generator, where you can generate such as This text or many other texts added to increase the number"
                          )
                        }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <!--  -->
            <div class="row">
              <HotelCard
                  v-for="(hotel, index) in data_filtered === true
                  ? copy_hotels
                  : fetchedHotels"
                  :key="index"
                  :credential_id="2"
                  :guest_nationality="nationalityValue.code"
                  :hotel="hotel"
                  :hotel_code="hotel.id"
                  :hotel_name="hotel.name"
                  :index="index"
                  :lat="hotel.lat"
                  :long="hotel.long"
                  :priceFetched="priceFetched"
                  :provider_id="2"
                  :resultIndex="null"
                  :session_id="null"
                  :show_loader="show_loader"
                  :show_price="show_price"
              />


              <!-- Hotel is empty & offline -->
              <div class="wrapper">

                <div v-if="!fetchedHotels.length && !copy_hotels.length" class="p-2 text-center mx-auto" style="width: 40%">
                  <EmptySearch :primaryColor="primary_color"/>
                </div>
                <a v-if="!fetchedHotels.length && !copy_hotels.length" :href="whatsappLink"
                   class="btn PrimaryButton w-fit-content"
                   target="_blank">{{ $t("contact us") + ' ' + $t("for_book") }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
</template>

<script>
import axios from "axios";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import HotelCard from "@/components/hotel/HotelCard.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Calendar from "primevue/calendar";
import moment from "moment";
// import { GoogleMap, Marker } from "vue3-google-map";
import {mapActions, mapState} from "pinia";
import {organizationSettings} from "@/store/modules/organizationSettings";
import EmptySearch from "@/components/hotel/EmptySearch.vue";


// const phoneNumber = '1234567890'; // Replace with the recipient's phone number
// const message = encodeURIComponent('Hello! This is a custom message.'); // Custom message
// const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;

// window.open(whatsappURL, '_blank'); // This will open the link in a new tab

export default {
  data() {
    return {
      zoom: 15,
      hotelData: {},
      hotelsData: {},
      hotelInfo: JSON.parse(localStorage.getItem("hotelDataSearch")),
      fetchedHotels: [],
      loading: false,
      session_id: "",
      priceValue: [0, 800],
      minValue: 0,
      maxValue: 5000,
      stepValue: 10,
      selectedStars: "",
      order_rate: false,
      order_price: false,
      countryValue: "",
      searchValue:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch")).hotel_name ==
              null
                  ? JSON.parse(localStorage.getItem("hotelDataSearch")).city_name
                  : JSON.parse(localStorage.getItem("hotelDataSearch")).hotel_name,
      hotel_name: "",
      cityValue: [],
      nationalities: [],
      nationalityValue: {
        id:
            JSON.parse(localStorage.getItem("hotelDataSearch")) == null
                ? ""
                : JSON.parse(localStorage.getItem("hotelDataSearch"))
                    .nationality_id,
        title:
            JSON.parse(localStorage.getItem("hotelDataSearch")) == null
                ? ""
                : JSON.parse(localStorage.getItem("hotelDataSearch"))
                    .nationality_name,
        code:
            JSON.parse(localStorage.getItem("hotelDataSearch")) == null
                ? ""
                : JSON.parse(localStorage.getItem("hotelDataSearch"))
                    .nationality_code,
      },
      hotelsSearch: [],
      citiesSearch: [],
      cityId:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch")).city_id,
      cityTitle:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch")).city_name,
      country_title:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch")).country_name,
      country_code:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch")).country_code,
      hotelValue: "",
      selectedDate:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? [new Date(), null]
              : [
                new Date(
                    moment(
                        JSON.parse(localStorage.getItem("hotelDataSearch")).check_in
                    ).format("MMM DD YYYY")
                ),
                new Date(
                    moment(
                        JSON.parse(localStorage.getItem("hotelDataSearch")).check_out
                    ).format("MMM DD YYYY")
                ),
              ],
      adults:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 1
              : JSON.parse(localStorage.getItem("hotelDataSearch")).rooms_guests[0]
                  .adults_count,
      childrens:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 1
              : JSON.parse(localStorage.getItem("hotelDataSearch")).rooms_guests[0]
                  .children_count,
      rooms:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 1
              : JSON.parse(localStorage.getItem("hotelDataSearch")).no_of_rooms,
      searchValueLaunch: "",
      searchValueReturn: "",
      edit_form: false,
      hotel_with_min_price: [],
      show_price: false,
      show_loader: true,
      rate: "",
      totalAdults: null,
      totalChildrens: null,
      copy_hotels: [],
      priceFetched: false,
      providers: JSON.parse(localStorage.getItem("providers")),
      hotelList: [],
      data_filtered: false,
      checkIn:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? new Date()
              : JSON.parse(localStorage.getItem("hotelDataSearch")).check_in,
      checkOut:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? new Date()
              : JSON.parse(localStorage.getItem("hotelDataSearch")).check_out,
    };
  },
  components: {
    EmptySearch,
    // Header,
    // hotelSearch,
    // GoogleMap,
    // Marker,
    Loading,
    HotelCard,
    VueSlider,
    Multiselect,
    Calendar,
  },
  computed: {
    ...mapState(organizationSettings, ["website_hotel_request_type", "whatsapp", "primary_color"]),
    whatsappLink() {
      const message = `اريد حجز ${this.rooms} غرفة\n` +
          `تاريخ الدخول: ${this.checkIn}\n` +
          `تاريخ الخروج: ${this.checkOut}\n` +
          `عدد البالغين: ${this.adults}\n` +
          `عدد الأطفال: ${this.childrens}`;
      return `https://wa.me/${this.whatsapp}?text=${encodeURIComponent(message)}`;
    }
  },
  methods: {
    ...mapActions(organizationSettings, ["getSettings"]),

    TotalGeusts() {
      this.totalAdults = 0;

      // Iterate through each room and add the number of adults to the total
      this.hotelInfo.rooms_guests.forEach((room) => {
        this.totalAdults += room.adults_count;
      });

      this.totalChildrens = 0;

      // Iterate through each room and add the number of adults to the total
      this.hotelInfo.rooms_guests.forEach((room) => {
        this.totalChildrens += room.children_count;
      });
    },

    //passNewFilter = {}
    async fetchHotel() {
      this.loading = true; //the loading begin
      this.hideFilter = false;
      this.hotelData = JSON.parse(localStorage.getItem("hotelDataSearch"));

      const filterWebPeds = {
        city_id: this.hotelData == null ? "" : this.hotelData.city_id,
        city_name: this.hotelData == null ? "" : this.hotelData.city_name,
        country_name: this.hotelData == null ? "" : this.hotelData.country_name,
        country_code: this.hotelData == null ? "" : this.hotelData.country_code,
        nationality_code:
            this.hotelData == null ? "" : this.hotelData.nationality_code,
        nationality_name:
            this.hotelData == null ? "" : this.hotelData.nationality_name,
        nationality_id:
            this.hotelData == null ? "" : this.hotelData.nationality_id,
        no_of_rooms: this.hotelData == null ? "" : this.hotelData.no_of_rooms,
        passenger_country_of_residence_code:
            this.hotelData == null
                ? ""
                : this.hotelData.passenger_country_of_residence_code,
        converter_status: 1,
        rooms_guests: [
          {
            rate_basis: "1",
            adults_count:
                this.hotelData == null
                    ? 1
                    : this.hotelData.rooms_guests[0].adults_count,
            children_count:
                this.hotelData == null
                    ? 0
                    : this.hotelData.rooms_guests[0].children_count,
            children_ages:
                this.hotelData == null
                    ? 0
                    : this.hotelData.rooms_guests[0].children_ages,
          },
        ],
        check_in: this.hotelData == null ? null : this.hotelData.check_in,
        check_out: this.hotelData == null ? null : this.hotelData.check_out,
        // meta_data: {
        //   credential_id: 2,
        //   provider_id: 2,
        // },
      };
      localStorage.setItem("bookDetails", JSON.stringify(filterWebPeds));

      // Fetch webpeds
      await axios
          .post("/fetch_local_hotels", filterWebPeds)
          .then((response) => {
            const data = response.data;

            // Set the meta description
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
              metaDescription.setAttribute('content', this.$t("hotel_search"));
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'description';
              newMeta.content = this.$t("hotel_search");
              document.head.appendChild(newMeta);
            }
            // Set the meta title
            const metaTitle = document.querySelector('meta[name="title"]');
            if (metaTitle) {
              metaTitle.setAttribute('content', this.$t("hotel_search"));
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'title';
              newMeta.content = this.$t("hotel_search");
              document.head.appendChild(newMeta);
            }
            // Update the page title
            const titleEl = document.querySelector("head title");
            if (titleEl) {
              titleEl.textContent = this.$t("hotel_search");
            } else {
              console.warn("Title element not found!");
            }


            if (data.status === true) {
              this.loading = false; //the loading ended
              // Show the search result div after loading data
              this.showSearchResult = true;
              this.searchData = true;
              this.fetchedHotels = data.data;
              this.hotelsData = data.data;
              this.session_id = "";

              // filterWebPeds.is_contract=1;
              // filterWebPeds.meta_data = {
              //   "is_contract":1,
              //   "provider_id":2,
              //   "credential_id":2,
              // }
              // this.fetch_min_price_hotels(filterWebPeds);
              // console.log(this.providers)

              // website_hotel_request_type == 1 OFFLINE && website_hotel_request_type == 2 ONLINE
              if (this.website_hotel_request_type === 1) {
                this.providers.forEach((element) => {
                  if (element.is_contract === 1) {
                    filterWebPeds.is_contract = 1;
                    filterWebPeds.converter_status = 0;
                    filterWebPeds.meta_data = {
                      is_contract: 1,
                      provider_id: 2,
                      credential_id: 2,
                    };
                    this.fetch_min_price_hotels(filterWebPeds);
                  }
                });
              }
              if (this.website_hotel_request_type === 2) {
                this.providers.forEach((element) => {
                  if (element.is_contract === 1) {
                    filterWebPeds.is_contract = 1;
                    filterWebPeds.converter_status = 0;
                    filterWebPeds.meta_data = {
                      is_contract: 1,
                      provider_id: 2,
                      credential_id: 2,
                    };
                    this.fetch_min_price_hotels(filterWebPeds);
                  } else {
                    filterWebPeds.is_contract = 0;
                    filterWebPeds.converter_status = 1;
                    filterWebPeds.meta_data = {
                      provider_id: 2,
                      credential_id: 2,
                      is_contract: 0,
                    };
                    this.fetch_min_price_hotels(filterWebPeds);
                  }
                });
              }

              // console.log(this.providers)
            } else {
              Swal.fire({
                title: "",
                text: data.message || "Status false Error Fetch Local",
                icon: "error",
              });
              // console.log("false")
              this.loading = false;
              this.hideFilter = true;
            }
          })
          .catch(({error}) => {
            Swal.fire({
              title: "",
              text: error?.response?.message || "Catch Error Fetch Local",
              icon: "error",
            });
            // console.log("error")
            this.loading = false; //the loading ended
            this.hideFilter = true;
          });
    },

    async fetch_min_price_hotels(fetched_hotels) {
      await axios
          .post("/fetch_hotel_with_min_price", fetched_hotels)
          .then((response) => {
            const data = response.data;
            // console.log(data)
            if (data.status === true) {
              this.loading = false; //the loading ended
              this.hotel_with_min_price = data.data.data;
              this.showHotels = [];
              if (this.hotel_with_min_price.length) {
                this.hotel_with_min_price.map((hotel) => {
                  this.priceFetched = true;
                  let index = this.fetchedHotels.findIndex((element) => element.title === hotel.title);
                  if (index > -1) {
                    if (hotel.min_price > 0) {
                      // console.log(this.fetchedHotels);
                      this.fetchedHotels[index].price_exist = true;
                      let usedHotel = hotel;
                      usedHotel.index = (this.fetchedHotels.prices ?? []).length + 1;
                      (this.fetchedHotels[index] ?? []).prices.push(usedHotel);
                      // console.log(this.hotelList);
                      let hotleListIndex = this.hotelList.findIndex((element) => element.title === hotel.title);
                      if (hotleListIndex === -1) {
                        this.hotelList.push(hotel.title);
                      }
                    }
                  }
                });
                this.show_price = true;
                this.show_loader = false;
              } else if (!this.hotel_with_min_price.length && this.website_hotel_request_type === 1) {
                this.fetchedHotels = [];
                this.copy_hotels = []
              }

              // console.log(this.fetchedHotels)
            } else {
              Swal.fire({
                title: "",
                text: data.message || "Status false Error Fetch Min price",
                icon: "error",
              });
              // console.log("false")
              this.loading = false;
              this.hideFilter = true;
            }
          })
          .catch(({error}) => {
            Swal.fire({
              title: "",
              text:
                  error?.response?.data?.message || "Catch Error Fetch Min price",
              icon: "error",
            });
            this.fetchedHotels = [];
            this.copy_hotels = []
            // console.log("error")
            this.loading = false; //the loading ended
            this.hideFilter = true;
          });
    },

    filter_hotels() {
      this.data_filtered = true;
      this.copy_hotels = [...this.fetchedHotels];

      // hotel name search
      if (this.hotel_name !== "") {
        this.copy_hotels = this.copy_hotels.filter((ele) => {
          return (
              ele.title.toUpperCase().indexOf(this.hotel_name.toUpperCase()) > -1
          );
        });
      }

      // star count
      if (this.selectedStars !== "") {
        this.copy_hotels = this.copy_hotels.filter((ele) => {
          return ele.starCount === this.selectedStars;
        });
      }

      // price range
      console.log(this.priceValue);
      this.copy_hotels = this.copy_hotels.filter((ele) => {
        if (ele.prices.length > 0) {
          return (
              ele.prices[0]?.min_price > this.priceValue[0] &&
              ele.prices[0]?.min_price < this.priceValue[1]
          );
        }
      });

      // heighest rating
      if (this.order_rate === true) {
        for (let i = 0; i < this.copy_hotels.length; i++) {
          let lowest = i;
          for (let j = i + 1; j < this.copy_hotels.length; j++) {
            if (
                +this.copy_hotels[j].rating > +this.copy_hotels[lowest].rating
            ) {
              lowest = j;
            }
          }

          if (lowest !== i) {
            // Swap
            [this.copy_hotels[i], this.copy_hotels[lowest]] = [
              this.copy_hotels[lowest],
              this.copy_hotels[i],
            ];
          }
        }
      }

      // lowest price search
      if (this.order_price === true) {
        for (let i = 0; i < this.copy_hotels.length; i++) {
          if (this.copy_hotels[i].prices.length > 0) {
            let lowest = i;
            for (let j = i + 1; j < this.copy_hotels.length; j++) {
              if (
                  +this.copy_hotels[j].prices[0]?.min_price <
                  +this.copy_hotels[lowest].prices[0]?.min_price
              ) {
                lowest = j;
              }
            }

            if (lowest !== i) {
              // Swap
              [this.copy_hotels[i], this.copy_hotels[lowest]] = [
                this.copy_hotels[lowest],
                this.copy_hotels[i],
              ];
            }
          }
        }
      }

      // // lowest price and heighest rate
    },

    searchNationality(event) {
      const searchText = {word: event.target.value};
      axios
          .post(`/fetch_custom_countries`, searchText)
          .then((res) => (this.nationalities = res.data.data.data))
          .catch((err) => console.log(err));
    },


    // Debounce function with character length check
    debouncedSearchHotels(event) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        if (event.target.value.length <= 2) {
          return; // Exit if the input length is 2 or less
        }
        this.searchHotels(event);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },


    searchHotels(event) {
      if (this.isRequestPending) return;
      this.hotelSearchLoading = true;

      this.isRequestPending = true;
      const searchText = {word: event.target.value};
      axios
          .post(`/search_home_hotels`, searchText)
          .then(
              (res) => (
                  (this.hotelsSearch = res.data.data.hotels),
                      (this.citiesSearch = res.data.data.cities)
              )
          )
          .catch((err) => console.log(err));
      // console.log(searchText);
    },

    // fetch hotels
    sendHotelName(hotelName, hotelID, cityId, country_code, country_title) {
      this.hotelName = hotelName;
      this.hotelID = hotelID;
      this.cityTitle = null;
      this.searchValue = hotelName;
      this.country_code = country_code;
      this.country_title = country_title;
      this.cityId = cityId;
      this.citiesSearch.length = [];
      this.hotelsSearch = [];
      // console.log(this.hotelName)
      // console.log(this.hotelID)
    },
    sendCityId(cityTitle, cityId, country_code, country_title) {
      this.cityId = cityId;
      this.cityTitle = cityTitle;
      this.searchValue = cityTitle;
      this.country_code = country_code;
      this.country_title = country_title;
      this.hotelName = null;
      this.hotelID = null;
      this.citiesSearch = [];
      this.hotelsSearch = [];
      // console.log(this.cityTitle)
      // console.log(this.cityId)
    },
    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // Rooms Minus && Add
    minusQuantityRooms() {
      if (this.rooms > 1) {
        this.rooms -= 1;
      }
    },
    addQuantityRooms() {
      this.rooms += 1;
    },
    edit_search() {
      let fetchedHotel = {
        city_id: this.cityId,
        city_name: this.cityTitle,
        country_name: this.country_title,
        country_code: this.country_code,
        nationality_name: this.nationalityValue.title,
        nationality_id: this.nationalityValue.id,
        nationality_code: this.nationalityValue.code,
        passenger_country_of_residence_code: this.nationalityValue.code,
        no_of_rooms: this.rooms,
        check_in: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
        check_out: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
        converter_status: 1,

        rooms_guests: [
          {
            rate_basis: "1",
            adults_count: this.adults,
            children_count: this.childrens,
            children_ages: Array.from(
                {
                  length: this.hotelData == null ? 0 : this.hotelData.childrens,
                },
                () => 7
            ),
          },
        ],
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },

        city_title: this.cityTitle,
        is_near_by_search_allowed: false,

        from_price: this.priceValue[0] == null ? "" : this.priceValue[0],
        to_price: this.priceValue[1] == null ? "" : this.priceValue[1],
        order_rate: this.order_rate == false ? 1 : 2,
        order_price: this.order_price == false ? 1 : 2,
      };
      if (this.hotel_name != "") {
        fetchedHotel.hotel_name = this.hotel_name;
      }
      if (this.selectedStars != null) {
        fetchedHotel.rate = this.selectedStars;
        fetchedHotel.star_count = this.selectedStars;
      }

      axios
          .post("/fetch_local_hotels", fetchedHotel)
          .then(({data}) => {
            this.loading = false;
            (this.fetchedHotels = data.data),
                (this.session_id =
                    data.meta?.session_id == null ? null : data.meta.session_id);
            localStorage.setItem("hotelDataSearch", JSON.stringify(fetchedHotel));
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error?.data?.message || "unKnown Error",
              icon: "error",
            });
            this.loading = false; //the loading ended
          });

      this.fetch_min_price_hotels(fetchedHotel);
    },
  },
  mounted() {
    if (
        localStorage.getItem("hotelDataSearch") ||
        localStorage.getItem("providers")
    ) {
      this.fetchHotel();
    }
    this.TotalGeusts();
    // this.fetchWebStatus()
  },
};
</script>

<style scoped>
.w-fit-content {
  width: fit-content !important;
  display: block !important;
  margin: 0 auto !important
}

.dropdown-toggle.SecondButton {
  padding: 0.7rem 0.5rem !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
