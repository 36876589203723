<template>
  <ul class="tabs_side">
    <template v-for="(service, index) in sortedServices" :key="index">
      <li v-if="service.service_name === 'HOTEL'">
        <router-link :to="{ name: 'hotelBooking', hash: '#main' }" aria-label="hotels">
          <div class="content">
            <span>
              <i class="fa-solid fa-calendar-days"></i>
            </span>
            <h6>{{ $t("hotel_reservation") }}</h6>
          </div>
          <div class="icon mt-0">
            <i :class="['fa-solid' ,lang == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right']"></i>
          </div>
        </router-link>
      </li>

      <li v-if="service.service_name === 'FLIGHT'">
        <router-link :to="{ name: 'flightBooking', hash: '#flight' }" aria-label="flights">
          <div class="content">
            <span>
              <i class="fa-solid fa-plane"></i>
            </span>
            <h6>{{ $t("flight_reservation") }}</h6>
          </div>
          <div class="icon mt-0">
            <i :class="['fa-solid' ,lang == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right']"></i>
          </div>
        </router-link>
      </li>

      <li v-if="service.service_name === 'PACKAGE'">
        <router-link :to="{ name: 'umrahBookingProfile', hash: '#PACKAGE' }" aria-label="umrah">
          <div class="content">
            <span>
              <i class="fa-solid fa-kaaba"></i>
            </span>
            <h6>{{ $t("umrah_reservation") }}</h6>
          </div>
          <div class="icon mt-0">
            <i :class="['fa-solid' ,lang == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right']"></i>
          </div>
        </router-link>
      </li>
    </template>
    <li>
      <router-link :to="{ name: 'personalData', hash: '#personal' }" aria-label="personal-data">
        <div class="content">
          <span> <i class="fa-solid fa-user-plus"></i> </span>
          <h6>{{ $t("personal_data") }}</h6>
        </div>
        <div class="icon mt-0">
          <i :class="['fa-solid' ,lang == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right']"></i>
        </div>
      </router-link>
    </li>

    <li>
      <router-link :to="{ name: 'securitySetting', hash: '#password' }" aria-label="security">
        <div class="content">
          <span> <i class="fa-solid fa-lock"></i> </span>
          <h6>{{ $t("security") }}</h6>
        </div>
        <div class="icon mt-0">
          <i :class="['fa-solid' ,lang == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right']"></i>
        </div>
      </router-link>
    </li>

    <!-- <li>
      <router-link :to="{ name: 'travelPerferance' }">
        <div class="content">
          <span> <i class="fa-solid fa-shield-heart"></i> </span>
          <h6>{{ $t("passenger_preferences") }}</h6>
        </div>
        <div class="icon">
          <i class="fa-solid fa-chevron-left"></i>
        </div>
      </router-link>
    </li> -->

    <li>
      <button class="btn w-100" @click="removeToken">
        <div class="content">
          <span> <i class="fa-solid fa-right-from-bracket"></i> </span>
          <h6>{{ $t("log_out") }}</h6>
        </div>
        <div class="icon mt-0">
          <i :class="['fa-solid' ,lang == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right']"></i>
        </div>
      </button>
    </li>
    <!-- <li>
      <router-link to="#">
        <div class="content danger">
          <span> <i class="fa-solid fa-trash"></i> </span>
          <h6>{{ $t("delete_account") }}</h6>
        </div>
      </router-link>
    </li> -->
  </ul>
</template>

<script>
import { mapState } from "pinia";
import { organizationSettings } from "@/store/modules/organizationSettings";

export default {
  name: "tabs-component",

  data() {
    return {
      service_name: this.services && this.services?.map((service) => service),
      lang: localStorage.getItem("lang")
    };
  },
  computed: {
    ...mapState(organizationSettings, ["services"]),
    sortedServices() {
      const order = ["HOTEL", "FLIGHT", "PACKAGE", "TravelPackage"];
      return this.services.slice().sort((a, b) => {
        return order.indexOf(a.service_name) - order.indexOf(b.service_name);
      });
    },
  },
  methods: {
    removeToken: function () {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.$router.go("/");
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_tabs.scss";
</style> -->
