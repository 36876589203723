<template>
  <div class="testimonial">
    <div class="d-flex align-items-start justify-content-between mt-3">
      <div class="d-flex align-items-start mx-3 mb-3 gap-2">
        <div class="image"><img :src="client.image" :alt="client.name" loading="lazy" /></div>
        <div class="d-flex flex-column">
          <div class="name">{{ client.name }}</div>
          <div class="country">{{ client.country_title }}</div>
        </div>
      </div>
      <rate :rates="client.rate" />
    </div>
    <p class="mb-3" v-html="client.content"></p>
  </div>
</template>

<script>
import rate from "./rate.vue";
export default {
  name: "testimonialCard",
  components: { rate },
  props: ["client"],
};
</script>

<!-- <style lang="scss" scoped>
  @import "./_testimonialCard.scss";
</style> -->
