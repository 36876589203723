<template>
  <section class="policy py-3">
    <div class="container">
      <h2 class="mx-0 px-0">{{ $t("privacy policy") }}</h2>
      <p v-html="policy.description"></p>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "policy-home",
  data() {
    return {
      policy: [],
    };
  },
  methods: {
    fetchPolicy() {
      axios.get("/policy").then(({ data }) => {
        this.policy = data.data;

        // Set the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', this.$t("privacy policy"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'description';
          newMeta.content = this.$t("privacy policy");
          document.head.appendChild(newMeta);
        }
        // Set the meta title
        const metaTitle = document.querySelector('meta[name="title"]');
        if (metaTitle) {
          metaTitle.setAttribute('content', this.$t("privacy policy"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'title';
          newMeta.content = this.$t("privacy policy");
          document.head.appendChild(newMeta);
        }
        // Update the page title
        const titleEl = document.querySelector("head title");
        if (titleEl) {
          titleEl.textContent = this.$t("privacy policy");
        } else {
          console.warn("Title element not found!");
        }
        // console.log(this.policy)
      });
    },
  },
  mounted() {
    this.fetchPolicy();
  },
};
</script>

<style></style>
