<template>
  <!-- <h4 v-if="$route.path !== '/blogs'">
    {{ blog?.title }}
  </h4> -->

  <router-link :to="{ path: `/blog/${blog.slug}` }" class="card-main">
    <div class="img-card">
      <img :src="blog.image" :alt="blog.title" loading="lazy" class="rounded" />
    </div>
    <div class="text-card w-100 p-2">
      <h4 class="title_blog">
        {{ blog?.title }}
      </h4>
      <p
        class="text_blog"
        v-html="blog?.content?.substring(0, 250) + ' ...'"
      ></p>
      <span class="broen">
        {{ $t("read_more") }}
      </span>
    </div>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      blog_query: {}
    }
  },
  props: {
    blog: {
      title: "",
      content: "",
      image: "",
      slug: "",
      blog_category: [],
      id: "",
      date: "",
      hashtag_blogs: [],
    },
  },
  mounted() {
    this.blog_query = JSON.stringify({
      title: this.blog?.title,
      content: this.blog?.content.slice(0, 50).replace(/\s+/g, '_'),
      image: this.blog?.image,
      category: this.blog?.blog_category?.title,
      id: this.blog?.id,
      date: this.blog?.date,
      // hashtags: this.blog?.hashtag_blogs?.map(hashtag => hashtag.title.replace(/\s+/g, '_')),
    });
  },
  // watch: {
  //   $route() {
  //     console.log(this.$route.path);
  //   },
  // },
};
</script>

<style></style>
