<template>
  <section class="top-packages mt-4 pb-4">
    <h2 class="text-center fw-bold mb-3">
      {{ $t("Learn about the best offers in the world") }}
    </h2>
    <h3 class="text-center h6 fw-light text-secondary mb-3">
      {{
        $t(
          "Travelers looking for the best places in the world also booked here"
        )
      }}
    </h3>
    <div class="container">
      <div class="row">
        <div class="top-packages-grid">
          <router-link
            class="top-packages-card"
            v-for="(pack, index) in packages.data"
            :key="index"
            :aria-label="pack.title"
            :to="`/package_details/${pack.id}`"
          >
            <!-- @click="get_top_packages(1, pack.id)" -->
            <img :src="pack?.main_image" @error="setDefaultImage" loading="lazy" :alt="pack?.title" />
            <div class="d-flex flex-column justify-content-between">
              <p class="base-price">
                {{ $t("starting from") }} {{ pack.base_price.adult }}
              </p>
              <div>
                <h4>{{ pack.title }}</h4>
                <div class="d-flex align-items-center mx-0">
                  <rate :rates="pack.star_category.star_count" class="m-0" />
                </div>
                <div class="d-flex align-items-center flex-wrap gap-2">
                  <div
                    class="featured d-flex align-items-center"
                    v-for="(i, index) in pack.package_services"
                    :key="index"
                  >
                    <img src="@/assets/media/image/shield-tick.svg" alt="shield" />
                    <p class="fw-light mb-0">{{ $t(i) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="row">
        <pagination
          :data="packages"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="get_top_packages"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import rate from "@/components/rate.vue";
import pagination from "laravel-vue-pagination";

export default {
  data() {
    return {
      packages: [],
    };
  },
  props: {},
  components: {
    rate,
    pagination,
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.$route.params.type == "umrah" ? require("@/assets/media/image/umrah/packageUmrah.png") : require("@/assets/media/image/umrah/three.png");
      this.isError = true; // Set isError to true when an error occurs
    },
    async get_top_packages(page = 1) {
      const formData = new FormData();
      if (this.$route.params.type == "umrah") {
        formData.append("country_id", 598);
      }
      formData.append(
        "package_tourism_type",
        this.$route.params.type == "umrah" ? 1 : 2
      );

      await axios
        .post(`/fetch_package_by_country_id?page=${page}`, formData)
        .then((data) => {
          if (
            this.$route.path == "/package/umrah" ||
            this.$route.path == "/package/tourism"
          ) {
            this.packages = data.data.data;
          } else {
            this.packages = data.data.data.data.slice(0, 6);
            // console.log(this.packages, "top_packages");
          }
          // console.log(this.packages, "top_packages");
        });
    },
  },
  async created() {
    await this.get_top_packages();
  },
  watch: {
    $route: {
      handler: "get_top_packages",
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
