<template>
<!--  <DeferredContent @load="fetchOrganizationData">-->
  <footer class="mt-5">
    <!-- <img :src="logo" class="circle-footer" /> -->
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="info_column">
            <router-link to="/" aria-label="logo">
              <img :src="logo" alt="logo" class="logo"/>
            </router-link>
            <p>
              {{
                $t(
                    "Booking, reviews and advice on hotels, resorts, flights, vacation rentals, travel packages and more"
                )
              }}
            </p>
            <ul class="info">
              <li class="d-flex align-items-center">
                <img alt="" src="@/assets/media/image/call-calling.svg"/>
                <div class="info-body">
                  <!-- <div class="info-text" onclick="window.open('tel:201003802708');">+201003802708</div> -->
                  <div class="info-text">
                    <a :href="`tel:${phone}`" aria-label="phone">{{ phone }}</a>
                  </div>
                </div>
              </li>
              <li class="d-flex align-items-center">
                <img alt="" src="@/assets/media/image/sms.svg"/>
                <div class="info-body">
                  <div class="info-text">
                    <a :href="`mailto:${email}`" aria-label="email">{{ email }}</a>
                  </div>
                  <!-- <div class="info-text" @click='window.location.href = "mailto:yasmeensoliman448@gmail.com?subject=Subject&body=message%20goes%20here"'>info@travelsofttech.com</div> -->
                </div>
              </li>
              <li class="d-flex">
                <img alt="Icon" src="@/assets/media/image/Icon.svg"/>
                <div class="info-body">
                  <div class="info-text">
                    <span>{{ address }}, {{city?.title}}, {{city?.country_title}}</span>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="license">
              <li>
                <span>{{ $t("license_number") }}: </span>{{ license_number }}
              </li>
              <li>
                <span>{{ $t("commercial_registration_number") }}: </span>
                {{ commercial_registration_number }}
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6 col-md-3 col-lg-2">
          <div class="links">
            <div class="title">
              <h2>{{ $t("company") }}</h2>
              <!-- <img src="@/assets/media/image/signal-line.png" /> -->
            </div>
            <ul>
              <li>
                <router-link class="footer-link" aria-label="home" to="/">{{
                    $t("main")
                  }}
                </router-link>
              </li>
              <template v-for="(service, index) in services" :key="index">
                <li v-if="service.service_name === 'FLIGHT'">
                  <router-link class="footer-link" to="/flights" aria-label="flights">{{
                      $t("Flights")
                    }}
                  </router-link>
                </li>
                <li v-if="service.service_name === 'HOTEL'">
                  <router-link class="footer-link" to="/hotels" aria-label="hotels">{{
                      $t("Hotels")
                    }}
                  </router-link>
                </li>
                <li v-if="service.service_name === 'PACKAGE'">
                  <router-link class="footer-link" to="/package/umrah" aria-label="umrah">{{
                      $t("UMRAH PACKAGES")
                    }}
                  </router-link>
                </li>
              </template>
              <li>
                <router-link class="footer-link" to="/cardSale" aria-label="offers">{{
                    $t("offers")
                  }}
                </router-link>
              </li>
              <li v-if="webPath == 'https://kanaya.x-coders.net'">
                <router-link class="footer-link" to="/sign-in" aria-label="affiliate"
                >Affiliate
                </router-link
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6 col-md-3 col-lg-2">
          <div class="links">
            <div class="title">
              <h2>{{ $t("other links") }}</h2>
              <!-- <img src="@/assets/media/image/signal-line.png" /> -->
            </div>
            <ul>
              <li>
                <router-link class="footer-link" to="/contact" aria-label="contact">{{
                    $t("contact us")
                  }}
                </router-link>
              </li>
              <li>
                <router-link class="footer-link" to="/about" aria-label="about">{{
                    $t("about us")
                  }}
                </router-link>
              </li>
              <li>
                <a class="footer-link" aria-label="blogs" target="_blank" href="https://blog.jewaralsafwah.com">{{
                    $t("blogs")
                  }}
                </a>
              </li>
              <li>
                <router-link class="footer-link" to="/terms" aria-label="terms">{{
                    $t("Terms and conditions")
                  }}
                </router-link>
              </li>
              <li>
                <router-link class="footer-link" to="/policy" aria-label="policy">{{
                    $t("Policies")
                  }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <div class="subscribe_column">
            <div class="title">
              <h2>{{ $t("Know everything new and new") }}</h2>
              <p class="text-white">
                {{
                  $t(
                      "Subscribe now to find out the latest offers about hotels, flights, and tourist resorts"
                  )
                }}
              </p>
            </div>
            <form @submit.prevent="subscripe()">
              <div class="">
                <i class="fa-solid fa-phone"></i> <input
                  v-model="phone_subpscription"
                  :placeholder="$t('enter_your_phone')"
                  inputmode="numeric"
                  name="phone"
                  type="text"
              />
                <button class="btn subscribe">{{ $t("Subscripe") }}</button>
              </div>
            </form>
            <ul class="social d-flex align-items-center">
              <li v-if="facebook != ''" class="icon-container">
                <a :href="facebook" target="_blank" aria-label="facebook"
                ><i class="fa-brands fa-facebook-f"></i
                ></a>
              </li>
              <li v-if="instagram != ''" class="icon-container">
                <a :href="instagram" target="_blank" aria-label="instagram"
                ><i class="fa-brands fa-instagram"></i
                ></a>
              </li>
              <li v-if="linkedin != ''" class="icon-container">
                <a :href="linkedin" target="_blank" aria-label="linkedin"
                ><i class="fa-brands fa-linkedin-in"></i
                ></a>
              </li>
              <li v-if="twitter != ''" class="icon-container">
                <a :href="twitter" target="_blank" aria-label="twitter"
                ><i class="fa-brands fa-twitter"></i
                ></a>
              </li>
              <li v-if="snapchat != ''" class="icon-container">
                <a :href="snapchat" target="_blank" aria-label="snapchat"
                ><i class="fa-brands fa-snapchat"></i
                ></a>
              </li>
              <li v-if="tiktok != ''" class="icon-container">
                <a :href="tiktok" target="_blank" aria-label="tiktok"
                ><i class="fa-brands fa-tiktok"></i
                ></a>
              </li>
              <li v-if="whatsapp != ''" class="icon-container">
                <a :href="whatsapp" target="_blank" aria-label="whatsapp"
                ><i class="fa-brands fa-whatsapp"></i
                ></a>
              </li>
              <li v-if="youtube != ''" class="icon-container">
                <a :href="youtube" target="_blank" aria-label="youtube"
                ><i class="fa-brands fa-youtube"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr class="pb-0 mb-0"/>
    <div class="company_info py-2">
      <div
          class="d-flex align-items-center justify-content-around company_info_data"
      >
<!--        <p>-->
<!--          &copy; {{ $t("all rights are save") }} @ {{ $t("website") }} {{ $t(title ?? "") }}-->
<!--          2023-->
<!--        </p>-->
        <p>{{ $t("Made by X Coders coding with expert") }}</p>
      </div>
    </div>
  </footer>
<!--  </DeferredContent>-->
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
  },
  name: "footer-layout",
  data() {
    return {
      logo: null,
      address: "",
      email: "",
      phone: "",
      city: {},
      license_number: null,
      commercial_registration_number: null,
      facebook: "",
      instagram: "",
      linkedin: "",
      twitter: "",
      snapchat: "",
      tiktok: "",
      whatsapp: "",
      youtube: "",
      title: "",
      services: [],
      webPath: window.location.origin,
      message: "",
      phone_subpscription: "",
    };
  },
  methods: {
    fetchOrganizationData() {
      axios.get("/organization_settings_index").then(({data}) => {
        this.title = data.data == null ? null : data.data.title;
        this.logo = data.data == null ? null : data.data.logo;
        this.email = data.data == null ? null : data.data.email;
        this.phone = data.data == null ? null : data.data.phone;
        this.license_number =
            data.data == null ? null : data.data.license_number;
        this.commercial_registration_number =
            data.data == null ? null : data.data.commercial_registration_number;
        this.address = data.data == null ? null : data.data.address;
        this.city = data.data == null ? null : data.data.city;
        this.facebook = data.data == null ? null : data.data.facebook;
        this.instagram = data.data == null ? null : data.data.instgram;
        this.linkedin = data.data == null ? null : data.data.linkedin;
        this.twitter = data.data == null ? null : data.data.twitter;
        this.snapchat = data.data == null ? null : data.data.snapchat;
        this.tiktok = data.data == null ? null : data.data.tiktok;
        this.whatsapp = data.data == null ? null : data.data.whatsapp;
        this.youtube = data.data == null ? null : data.data.youtube;
        this.services =
            data?.data && data?.data?.services.length > 0 && data?.data?.services;
      });
    },

    subscripe() {
      axios
          .post("/store_news_letter_phone", {phone: this.phone_subpscription})
          .then(({data}) => {
            this.message = data.data;
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.message,
              timer: 3000,
            });
            window.open(`${this.whatsapp}`, "_blank");
            this.phone_subpscription = "";
          })
          .catch((error) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: error?.response?.data?.message,
              timer: 3000,
            });
            return error;
          })
          .finally(() => {
          });
    },
  },
  created() {
    this.fetchOrganizationData();
  }
};
</script>
