import {defineStore} from "pinia";
import axios from "axios";
// import { writeFileSync } from 'fs';


export const hotelsPage = defineStore("hotelsPage", {
    state: () => ({
        sections: [],
        hotel: {},
        loading: false
    }),
    actions: {
        async GetHotelsPage() {
            try {
                const response = await axios.post("/hotel_card_website");
                this.sections = response.data.data;
                this.hotel = response.data?.data[0]?.hotels[0];
                // console.log(this.hotel, "sections");
            } catch (error) {
                this.loading = false;
                console.error("Error fetching organization settings:", error);
            } finally {
                this.loading = false;
            }
        },
    },
});
