<script>
import navBar from "./layout/navbar.vue";
import Footer from "./layout/footer.vue";
import {organizationSettings} from "@/store/modules/organizationSettings";
import { mapActions, mapState } from "pinia";
import cardCampaign from "@/view/campaign/components/cardCampaign.vue";
import axios from "axios";

export default {
  name: "campaign-page",
  data() {
    return {
      slug: this.$route.params.slug,
      campaign: {}
    };
  },
  methods: {
    ...mapActions(organizationSettings, ["getSettings"]),
    fetchAdvertisingCampaign() {
      const formData = new FormData();
      formData.append("slug", this.slug)
      axios.post("/show_advertising_campaign", formData).then(({data}) => {
        this.campaign = data.data;
      });
    },
    fetchTheme() {
      // if (localStorage.getItem("Theme") === 2) {
      //   import("@/assets/scss/style.min.css");
      // } else if (localStorage.getItem("Theme") === 1) {
      //   import("@/assets/new_tem/style.min.css");
      // }
      axios.get("/organization_theme").then((data) => {
        this.PrimaryColor = data.data.data.primary_color;
        this.SecondColor = data.data.data.secondary_color;
        this.NavbarColor = data.data.data.navbar_color;
        this.FooterColor = data.data.data.footer_color;
        this.HeaderColor = data.data.data.header_color;
        // console.log(this.PrimaryColor)
        // console.log(this.SecondColor)
        const style = document.createElement("style");
        style.innerHTML = `:root { --primary-color: ${this.PrimaryColor}; --second-color: ${this.SecondColor}; --header-color: ${this.HeaderColor}; --footer-color: ${this.FooterColor}; --navbar-color: ${this.NavbarColor} }`;
        document.head.appendChild(style);
        // console.log(data.data.data)
        // document.head.favIcon = data.data.data.favicon;
        // this.updatePrimaryColor(this.PrimaryColor, this.SecondColor);
        // if (data.data.data.theme_number === 2) {
        //   import("@/assets/scss/style.min.css");
        //   localStorage.setItem("Theme", 2);
        // } else if (data.data.data.theme_number === 1) {
        //   import("@/assets/new_tem/style.min.css");
        //   localStorage.setItem("Theme", 1);
        // }
      });
    },
  },
  computed: {
    ...mapState(organizationSettings, [
      "logo",
      "whatsapp",
      "description"
    ]),
  },
  async mounted() {
    await this.getSettings();
    this.fetchTheme();
    this.fetchAdvertisingCampaign()
  },
  components: {
    navBar,
    Footer,
    cardCampaign
  }
}
</script>

<template>
  <navBar :logo="logo" />
  <div class="header_campaign">
    <img src="../../assets/media/image/umrah_cover.webp" class="header_campaign_image" alt="umrah_cover">
    <div class="header_campaign_text">
      <h1>{{ $t("campaign") }}</h1>
      <p>{{ $t("campaign_text") }}</p>
    </div>
  </div>
  <div class="campaign my-3">
    <div class="container">
      <div class="row">
        <h2 class="fw-bold mb-3">{{campaign.name}}</h2>
        <div class="col-lg-6 col-md-6 col-12" v-for="(hotel, index) in campaign.hotels" :key="index">
          <cardCampaign :hotel="hotel" :whatsapp="whatsapp" />
        </div>
      </div>
    </div>
  </div>
  <Footer :logo="logo" :description="description" />
</template>

<style lang="scss" scoped>
  .header_campaign {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    position: relative;
    .header_campaign_image {
      width: 100%;
      height: 100%;
      filter: brightness(0.7);
    }
    .header_campaign_text {
      position: absolute;
      color: #fff;
      width: 45%;
      text-align: center;
      h1 {
        font-family: "bold", serif
      }
      p {
        font-family: "regular", serif;
        font-size: 1rem;
        margin-top: 1rem;
      }
    }
  }
</style>