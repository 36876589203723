<template>
  <genrateCode v-if="dialogCode" />

  <div class="" v-else>
    <button
      @click="visible = true"
      type="button"
      style="
        font-size: 0.9rem;
        outline: none;
        border: none;
        background: transparent;
        color: #000;
      "
    >
      <span> {{ $t("forgot your password ?") }}</span>
    </button>
    <Dialog
      v-model:visible="visible"
      modal
      :header="$t('forgot your password ?')"
      :style="{ width: '35rem' }"
    >
      <span class="p-text-secondary block mb-5">
        {{ $t("email") }}
      </span>

      <div class="flex align-items-center my-4 gap-3">
        <!-- <label for="email" class="font-semibold w-6rem"></label> -->
        <InputText
          id="email"
          class="flex-auto py-2 w-100"
          v-model="email"
          autocomplete="off"
          :placeholder="$t('enter email')"
          required
        />
      </div>
      <div class="flex justify-content-end gap-2">
        <button
          type="button"
          :label="$t('save')"
          class="PrimaryButton"
          severity="secondary"
          v-if="loading"
        >
          <i class="fa-solid fa-spinner fa-spin"></i>
        </button>
        <button
          v-else
          type="button"
          :disabled="!email"
          class="PrimaryButton"
          @click="sumbit"
        >{{ $t("save") }}</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import axios from "axios";
import Swal from "sweetalert2";
import genrateCode from "./genrateCode.vue";

export default {
  data() {
    return {
      visible: false,
      loading: false,
      email: "",
      dialogCode: false,
    };
  },
  components: {
    Dialog,
    InputText,
    genrateCode,
  },
  methods: {
    sumbit() {
      this.loading = true;
      axios
        .post("/check_cerdentail_client", { email: this.email })
        .then((res) => {
          console.log(res.data, "res");
          this.visible = false;
          this.$store.state.emailUser = this.email;
          this.$router.push({ name: "genrateCode" });
          this.loading = false;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
          this.visible = false;
          this.loading = false;
        });
    },
  },
};
</script>
