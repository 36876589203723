<template>
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-6 col-12">
        <div class="d-flex align-items-center">
          <div class="head">
            <h2>{{ hotelData == null ? null : hotelData.title }}</h2>
            <ul class="facilities mb-0">
              <li v-for="(facility, index) in hotelData.facilities" :key="index" :tooltip="facility.title" flow="up">
                <i :class="`fa-solid fa-${facility.icon}`"></i>
              </li>
            </ul>
          </div>
          <rate :rates="hotelData.starCount == null ?? 0 ? 5 : hotelData.starCount" />
        </div>
        <ul class="location">
          <li
              class="d-flex flex-column align-items-center justify-content-start w-100"
          >
            <div class="d-flex w-100 mb-3">
              <!--            <i class="fa-solid fa-circle-info"></i>-->
              <p class="hotel_description_text mb-0">
                {{ hotelData.description }}
              </p>
            </div>
            <div class="d-flex w-100 mb-3">
              <p class="w-100 mb-0">
                <i class="fa-solid fa-location-dot"></i>
                {{ hotelData == null ? null : hotelData.address_details }}
              </p>
              <!--            <p>-->
              <!--              <i class="fa-solid fa-bed"></i> {{ hotelData.no_of_rooms }}-->
              <!--              {{ $t("the room") }}-->
              <!--            </p>-->
            </div>

            <div class="d-flex flex-column w-100 mb-3">
              <p class="w-100 mb-3">
                <i class="fa-regular fa-clock"></i> {{ $t("check_in") }}
                {{ hotelData.check_in ?? "14:00" }}
              </p>
              <p class="w-100">
                <i class="fa-regular fa-clock"></i> {{ $t("check_out") }}
                {{ hotelData.check_out ?? "12:00" }}
              </p>
            </div>
          </li>
        </ul>
        <div class="address_hotel">
          <div class="data">
            <a
                class="show_map text-primary cursor-pointer"
                :href="`https://www.google.com/maps?q=${
              hotelData == null ? null : hotelData.lat
            },${hotelData == null ? null : hotelData.long}&z=${this.zoom}`"
                target="_blank"
            >{{ $t("show_map") }}</a
            >
          </div>
          <!--        <div class="map">-->
          <!--          <GoogleMap-->
          <!--            :center="{-->
          <!--              lat: parseFloat(hotelData == null ? null : hotelData.lat),-->
          <!--              lng: parseFloat(hotelData == null ? null : hotelData.long),-->
          <!--            }"-->
          <!--            :zoom="zoom"-->
          <!--            :api-key="googleMapsApiKey"-->
          <!--          >-->
          <!--            <Marker-->
          <!--              :position="{-->
          <!--                lat: parseFloat(hotelData == null ? null : hotelData.lat),-->
          <!--                lng: parseFloat(hotelData == null ? null : hotelData.long),-->
          <!--              }"-->
          <!--              :icon="customIcon"-->
          <!--            />-->
          <!--          </GoogleMap>-->
          <!--        </div>-->
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="images">
          <div class="show_images">
            <div
                class="single_image"
                v-for="(img, index) in MyCurrentImages"
                :key="index"
            >
              <img
                  @error="setDefaultImage"
                  :src="
                img.image
                  ? img.image
                  : 'https://softtech.crazyidea.online/uploads/organization_settings/898951703760775.png'
              "
                  :alt="index"
                  :class="{ 'error-image': isError }"
                  data-bs-toggle="modal"
                  :data-bs-target="`#imageModal${index}`"
              />

              <!-- <div class="number_badge" v-if="index==0">
                +<span>{{images.length}}</span>
                <i class="fa-regular fa-images"></i>
              </div> -->
            </div>
            <div
                class="modal fade"
                v-for="(img, index) in images == null ? null : images"
                :key="index"
                :id="`imageModal${index}`"
                tabindex="-1"
                aria-labelledby="imageModalLabel"
                aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="head_gallery">
                      <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                      ></button>
                      <p class="length">
                        {{ images == null ? null : images.length }} /
                        {{ index + 1 }}
                      </p>
                    </div>
                    <div class="image">
                      <i
                          class="fa-solid fa-chevron-right"
                          data-bs-toggle="modal"
                          :data-bs-target="`#imageModal${index + 1}`"
                          :class="{ disable: index + 1 == images.length }"
                      ></i>
                      <img :src="images[index].image" :alt="index" />
                      <i
                          class="fa-solid fa-chevron-left"
                          data-bs-toggle="modal"
                          :data-bs-target="`#imageModal${index - 1}`"
                          :class="{ disable: index + 1 == 1 }"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="number_badge">
              +<span>{{ images == null ? null : images.length }}</span>
              <i class="fa-regular fa-images"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { GoogleMap, Marker } from "vue3-google-map";
import rate from "../rate.vue";
// import moment from "moment";

export default {
  name: "image-detials-hotel",
  props: {
    images: Array,
    hotelData: Object,
  },
  components: {
    // GoogleMap,
    // Marker,
    rate,
  },
  data() {
    return {
      customIcon: {
        googleMapsApiKey: "AIzaSyBq5mIzsL7DUmbpstw_RadJ3yucjmSyy_s",
        url: "https://cdn-icons-png.flaticon.com/512/11083/11083698.png",
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      isError: false,
      zoom: 15, // Default zoom level,
    };
  },
  computed: {
    MyCurrentImages() {
      return this.images == null ? null : this.images.slice(0, 5);
    },
    googleMapsApiKey() {
      console.log(process.env.VUE_APP_GOOGLE_MAPS_API_KEY , "hi")
      return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    },
  },
  methods: {
    formatTime(time) {
      var timeString = time;
      var timeParts = timeString.split(":");
      var hours = parseInt(timeParts[0], 10);
      var minutes = parseInt(timeParts[1], 10);

      var date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);

      var formattedTime = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      return formattedTime;
    },
    setDefaultImage(event) {
      event.target.src = localStorage.getItem("DefaultImage");
      this.isError = true; // Set isError to true when an error occurs
    },
  },
};
</script>

<style lang="scss" scoped>
  .hotel_description_text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(var(--line-height) * 3em);
    line-height: var(--line-height);
    --line-height: 1.5;
  }
  .facilities {
    display: flex;
    flex-wrap: wrap;
    gap: 10px
  }
</style>
