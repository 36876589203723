<template>
  <vue-json-ld :head="true" :jsonld="jsonld" />
  <section class="hotel_detials">
    <div class="container-fluid">
      <div v-if="loading" class="loading">
        <Loading/>
      </div>
      <headerImages :hotelData="hotelData == null ? null : hotelData"/>
      <Images
          :hotelData="hotelData == null ? null : hotelData"
          :images="hotelData == null ? null : hotelData.images"
      />
      <div class="container mt-2">
        <form>
           <h5 class="border-bottom pb-2 w-25">{{$t("edit_search")}}</h5>
          <div class="selects">
            <div class="row align-items-end">
              <div class="col-12 col-md-6 col-lg-3 my-2">
                <div class="form-group">
                  <label class="form-label">{{ $t("city") }}</label>
                  <input
                      v-model="searchValue"
                      :placeholder="$t('choose_city')"
                      class="form-control"
                      type="search"
                      @keyup="searchHotels"
                  />
                  <div
                      v-if="
                    searchValue == null
                      ? null
                      : searchValue.length > 2 &&
                        (citiesSearch.length || hotelsSearch.length)
                  "
                      class="resultSearch"
                  >
                    <h6 v-if="citiesSearch.length" class="head_search">
                      {{ $t("The Cities") }}
                    </h6>
                    <ul v-if="citiesSearch.length" class="cities">
                      <li
                          v-for="(city, index) in citiesSearch"
                          :key="index"
                          @click="
                        sendCityId(
                          city?.title,
                          city.id,
                          city?.country_code,
                          city?.country_title
                        )
                      "
                      >
                        <i class="fa-solid fa-location-dot"></i>
                        <div class="texts">
                          <p class="title">{{ city?.title }}</p>
                          <p class="country">{{ city?.country_title }}</p>
                        </div>
                      </li>
                    </ul>
<!--                    <h6 v-if="hotelsSearch.length" class="head_search">-->
<!--                      {{ $t("Hotels") }}-->
<!--                    </h6>-->
<!--                    <ul v-if="hotelsSearch.length" class="hotels">-->
<!--                      <li-->
<!--                          v-for="(hotel, index) in hotelsSearch"-->
<!--                          :key="index"-->
<!--                          @click="-->
<!--                        sendHotelName(-->
<!--                          hotel.title,-->
<!--                          hotel.hotel_code,-->
<!--                          hotel.city_id,-->
<!--                          hotel.country_code,-->
<!--                          hotel.country_title-->
<!--                        )-->
<!--                      "-->
<!--                      >-->
<!--                        <img-->
<!--                            :alt="hotel.title"-->
<!--                            :src="hotel.image"-->
<!--                            class="hotel_image"-->
<!--                        />-->
<!--                        <div class="texts">-->
<!--                          <p class="title">{{ hotel.title }}</p>-->
<!--                          <p class="country">-->
<!--                            {{ hotel.country_title }} , {{ hotel.city_title }}-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                    </ul>-->
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-2 my-2">
                <div class="form-group">
                  <label class="form-label">{{ $t("nationality_country") }}</label>
                  <Multiselect
                      v-model="nationalityValue"
                      :clear-on-select="true"
                      :hide-selected="true"
                      :options="nationalities"
                      :placeholder="$t('choose_nationality_country')"
                      label="title"
                      select-label=""
                      track-by="id"
                      @keyup="searchNationality"
                  >
                  </Multiselect>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3 my-2">
                <div class="form-group">
                  <div class="">
                    <label class="form-label d-block">{{
                        $t("period")
                      }}
                    </label>
                    <Calendar
                        v-model="selectedDate"
                        :manualInput="true"
                        dateFormat="dd/mm/yy"
                        selectionMode="range"
                        ariaLabel="Choose an date"
                        showIcon
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-2 my-2">
                <div class="form-group">
                  <div class="dropdown">
                    <label class="form-label" for="">
                      {{ $t("rooms") }}</label>
                    <button
                        aria-expanded="false"
                        class="btn btn-primary dropdown-toggle"
                        data-bs-auto-close="outside"
                        data-bs-toggle="dropdown"
                        type="button"
                    >
                      {{ $t("rooms") }} {{ rooms.length }} - {{ $t("Adults") }}
                      {{ adults }} - {{ $t("childrens") }} {{ childrens }}
                    </button>
                    <div class="dropdown-menu">
                      <div class="list">
                        <span>{{ $t("Adults") }}</span>
                        <div class="number">
                          <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantityAdults()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ adults }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityAdults()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="list">
                        <span>{{ $t("childrens") }}</span>
                        <div class="number">
                          <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantityChildrens()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ childrens }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityChildrens()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="list">
                        <span>{{ $t("rooms") }}</span>
                        <div class="number">
                          <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantityRooms()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ rooms }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityRooms()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12 my-2">
                <button
                    class="btn PrimaryButton search"
                    type="button"
                    @click="edit_search()"
                >
                  {{ $t("edit search") }}
                </button>
              </div>
            </div>
          </div>
          <div class="select-mobile">
            <button class="btn PrimaryButton search" type="button"
                    @click="visible = !visible">
              {{ visible ? $t("hidde search") : $t("edit search") }}
            </button>
            <div v-if="visible" id="collapseFilter" class="filter">
              <div class="col-12 col-md-6 col-lg-3 my-2">
                <label class="form-label">{{ $t("city") }}</label>

                <div class="form-group">
                  <input
                      v-model="searchValue"
                      :placeholder="$t('choose_hotel')"
                      class="form-control"
                      type="search"
                      @keyup="searchHotels"
                  />
                  <div
                      v-if="
                    searchValue == null
                      ? null
                      : searchValue.length > 2 &&
                        (citiesSearch.length || hotelsSearch.length)
                  "
                      class="resultSearch"
                  >
                    <h6 v-if="citiesSearch.length" class="head_search">
                      {{ $t("The Cities") }}
                    </h6>
                    <ul v-if="citiesSearch.length" class="cities">
                      <li
                          v-for="(city, index) in citiesSearch"
                          :key="index"
                          @click="
                        sendCityId(
                          city?.title,
                          city?.id,
                          city?.country_code,
                          city?.country_title
                        )
                      "
                      >
                        <i class="fa-solid fa-location-dot"></i>
                        <div class="texts">
                          <p class="title">{{ city?.title }}</p>
                          <p class="country">{{ city?.country_title }}</p>
                        </div>
                      </li>
                    </ul>
<!--                    <h6 v-if="hotelsSearch.length" class="head_search">-->
<!--                      {{ $t("Hotels") }}-->
<!--                    </h6>-->
<!--                    <ul v-if="hotelsSearch.length" class="hotels">-->
<!--                      <li-->
<!--                          v-for="(hotel, index) in hotelsSearch"-->
<!--                          :key="index"-->
<!--                          @click="-->
<!--                        sendHotelName(-->
<!--                          hotel.title,-->
<!--                          hotel.hotel_code,-->
<!--                          hotel.city_id,-->
<!--                          hotel.country_code,-->
<!--                          hotel.country_title-->
<!--                        )-->
<!--                      "-->
<!--                      >-->
<!--                        <img-->
<!--                            :alt="hotel.title"-->
<!--                            :src="hotel.image"-->
<!--                            class="hotel_image"-->
<!--                        />-->
<!--                        <div class="texts">-->
<!--                          <p class="title">{{ hotel.title }}</p>-->
<!--                          <p class="country">-->
<!--                            {{ hotel.country_title }} , {{ hotel.city_title }}-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                    </ul>-->
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-2 my-2">
                <div class="form-group">
                  <label class="form-label" for="">
                    {{ $t("nationality_country") }}</label>
                  <Multiselect
                      v-model="nationalityValue"
                      :clear-on-select="true"
                      :hide-selected="true"
                      :options="nationalities"
                      :placeholder="$t('choose_nationality_country')"
                      label="title"
                      select-label=""
                      track-by="id"
                      @keyup="searchNationality"
                  >
                  </Multiselect>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3 my-2">
                <div class="form-group">
                  <label class="form-label" for="">
                    {{ $t("period") }}</label>
                  <div class="card flex justify-content-center">
                    <Calendar
                        v-model="selectedDate"
                        :manualInput="true"
                        dateFormat="dd/mm/yy"
                        selectionMode="range"
                        ariaLabel="Choose an date"
                        showIcon
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-2 my-2">
                <div class="form-group">
                  <label class="form-label" for="">
                    {{ $t("rooms") }}</label>
                  <div class="dropdown">
                    <button
                        aria-expanded="false"
                        class="btn btn-primary dropdown-toggle"
                        data-bs-auto-close="outside"
                        data-bs-toggle="dropdown"
                        type="button"
                    >
                      {{ $t("rooms") }} {{ rooms.length }} - {{ $t("Adults") }}
                      {{ adults }} - {{ $t("childrens") }} {{ childrens }}
                    </button>
                    <div class="dropdown-menu">
                      <div class="list">
                        <span>{{ $t("Adults") }}</span>
                        <div class="number">
                          <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantityAdults()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ adults }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityAdults()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="list">
                        <span>{{ $t("childrens") }}</span>
                        <div class="number">
                          <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantityChildrens()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ childrens }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityChildrens()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="list">
                        <span>{{ $t("rooms") }}</span>
                        <div class="number">
                          <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantityRooms()"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ rooms }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityRooms()"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12 my-2">
                <button
                    class="btn PrimaryButton search"
                    type="button"
                    @click="edit_search()"
                >
                  {{ $t("edit search") }}
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>

      <TabMenu
          :ScrollUp="ScrollUp"
          :hotelData="hotelData == null ? null : hotelData"
      />
    </div>
  </section>
</template>

<script>
import headerImages from "@/components/hotelDetials/headerImages.vue";
// import Header from '@/components/home/Header.vue';
import Images from "@/components/hotelDetials/images.vue";
import TabMenu from "@/components/hotelDetials/tabs.vue";
// import Swal from "sweetalert2";
import moment from "moment";

import axios from "axios";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import Multiselect from "vue-multiselect";
import Calendar from "primevue/calendar";
import {organizationSettings} from "@/store/modules/organizationSettings";
import { mapActions, mapState } from "pinia";

export default {
  name: "hotel-detials",
  components: {
    headerImages,
    Images,
    TabMenu,
    Loading,
    Calendar,
    Multiselect,
  },
  data() {
    return {
      // page_params: this.$route.params.page,
      // hotelQuery: JSON.parse(this.$route.query?.hotel),
      hotelId: this.$route.params.id,
      hotelData: {
        images: [],
        image: "",
        title: "",
        address_details: "",
        description: "",
        city: {
          title: ""
        },
        country: {
          code: ""
        },
        starCount: "",
        facilities: [],
      },
      jsonld: {
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": "My Travel Agency",
        "url": "https://example.com",
        "logo": "https://example.com/logo.png",
        "image": "https://example.com/image.jpg",
        "description": "Description of the travel agency",
        "email": "q7MlS@example.com",
        "telephone": "+1234567890",
      },
      visible: false,
      loading: false,
      provider_id: JSON.parse(localStorage.getItem("hotelData"))?.meta_data
          ?.provider_id,
      citiesSearch: [],
      countryValue: "",
      searchValue:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.hotel_name ==
              null
                  ? JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_name
                  : JSON.parse(localStorage.getItem("hotelDataSearch"))?.hotel_name,
      hotel_name: "",
      cityValue: [],
      nationalities: [],
      nationalityValue: {
        id:
            JSON.parse(localStorage.getItem("hotelDataSearch"))
                ? JSON.parse(localStorage.getItem("hotelDataSearch"))
                    .nationality_id
                : "SA",
        title:
            JSON.parse(localStorage.getItem("hotelDataSearch")) == null
                ? ""
                : JSON.parse(localStorage.getItem("hotelDataSearch"))
                    .country_name,
        code:
            JSON.parse(localStorage.getItem("hotelDataSearch")) == null
                ? ""
                : JSON.parse(localStorage.getItem("hotelDataSearch"))
                    .nationality_code,
      },
      hotelsSearch: [],
      cityId:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_id,
      cityTitle:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_name,
      country_title:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.country_name,
      country_code:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.country_code,
      hotelValue: "",

      adults:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 1
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.rooms_guests[0]
                  ?.adults_count,
      childrens:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 1
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.rooms_guests[0]
                  ?.children_count,
      rooms:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 1
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.no_of_rooms,
      searchValueLaunch: "",
      searchValueReturn: "",
      edit_form: false,
      check_in:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? new Date()
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.check_in,
      check_out:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? null
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.check_out,
      selectedDate:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? [new Date(), null]
              : [
                new Date(
                    moment(
                        JSON.parse(localStorage.getItem("hotelDataSearch"))?.check_in
                    ).format("MMM DD YYYY")
                ),
                new Date(
                    moment(
                        JSON.parse(localStorage.getItem("hotelDataSearch"))?.check_out
                    ).format("MMM DD YYYY")
                ),
              ],
      // pageParams: this.$route.query.hotel.id,
    };
  },
  methods: {
    ...mapActions(organizationSettings, ["getSettings"]),

    async fetchHotelDetails() {
      this.loading = true; //the loading begin
      await axios
          .post("/fetch_new_hotel_details", {
            code: parseInt(this.hotelId),
            provider_id: this.provider_id,
          })
          .then(({data}) => {
            this.hotelData = data.data;
            // console.log(this.hotelData);

            const dataHotelSearch = {
              "city_id": this.hotelData?.city.id,
              "city_name": this.hotelData?.city?.title,
              "country_name": this.hotelData.city?.country_title,
              "country_code": this.hotelData.city?.country_code,
              "nationality_code": "SA",
              "no_of_rooms": 1,
              "passenger_country_of_residence_code": "SA",
              "converter_status": 1,
              "rooms_guests": [
                {
                  "rate_basis": "1",
                  "adults_count": 1,
                  "children_count": 0,
                  "children_ages": []
                }
              ],
              "check_in": moment(new Date()).format("YYYY-MM-DD"),
              "check_out": moment(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).format("YYYY-MM-DD"),
              "meta_data": {
                "credential_id": 2,
                "provider_id": this.provider_id,
              }
            }
            localStorage.setItem("hotelDataSearch", JSON.stringify(dataHotelSearch));
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
              metaDescription.setAttribute('content', this.hotelData.description);
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'description';
              newMeta.content = this.hotelData.description;
              document.head.appendChild(newMeta);
            }
            // Set the meta title
            const metaTitle = document.querySelector('meta[name="title"]');
            if (metaTitle) {
              metaTitle.setAttribute('content', this.hotelData.title);
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'title';
              newMeta.content = this.hotelData.title;
              document.head.appendChild(newMeta);
            }
            // Update the page title
            const titleEl = document.querySelector("head title");
            if (titleEl) {
              titleEl.textContent = this.hotelData.title;
            } else {
              console.warn("Title element not found!");
            }

            const ogTitle = document.querySelector('meta[property="og:title"]');
            if (ogTitle) {
              ogTitle.setAttribute('content', this.hotelData.title);
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'title';
              newMeta.content = this.hotelData.title;
              document.head.appendChild(newMeta);
            }
            const ogDescription = document.querySelector('meta[property="og:description"]');
            if (ogDescription) {
              ogDescription.setAttribute('content', this.hotelData.description);
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'description';
              newMeta.content = this.hotelData.description;
              document.head.appendChild(newMeta);
            }
            const ogImage = document.querySelector('meta[property="og:image"]');
            if (ogImage) {
              ogImage.setAttribute('content', this.hotelData.image);
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'title';
              newMeta.content = this.hotelData.image;
              document.head.appendChild(newMeta);
            }

            this.loading = false; //the loading ended
          })
          .catch((error) => {
            this.loading = false; //the loading ended
            return error;
          });
    },

    searchNationality(event) {
      const searchText = {word: event.target.value};
      axios
          .post(`/fetch_custom_countries`, searchText)
          .then((res) => (this.nationalities = res.data.data.data))
          .catch((err) => console.log(err));
    },
    searchHotels(event) {
      const searchText = {word: event.target.value};
      axios
          .post(`/search_home_hotels`, searchText)
          .then(
              (res) => (
                  (this.hotelsSearch = res.data.data.hotels),
                      (this.citiesSearch = res.data.data.cities)
              )
          )
          .catch((err) => console.log(err));
      // console.log(searchText);
    },

    // fetch hotels
    // sendHotelName(hotelName, hotelID, cityId, country_code, country_title) {
    //   this.hotelName = hotelName;
    //   this.hotelID = this.hotelQuery;
    //   this.cityTitle = null;
    //   this.searchValue = hotelName;
    //   this.country_code = country_code;
    //   this.country_title = country_title;
    //   this.cityId = cityId;
    //   this.citiesSearch.length = [];
    //   this.hotelsSearch = [];
    //   // console.log(this.hotelName)
    //   // console.log(this.hotelID)
    // },
    sendCityId(cityTitle, cityId, country_code, country_title) {
      this.cityId = cityId;
      this.cityTitle = cityTitle;
      this.searchValue = cityTitle;
      this.country_code = country_code;
      this.country_title = country_title;
      this.hotelName = null;
      this.hotelID = null;
      this.citiesSearch = [];
      this.hotelsSearch = [];
      // console.log(this.cityTitle)
      // console.log(this.cityId)
    },
    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // Rooms Minus && Add
    minusQuantityRooms() {
      if (this.rooms > 1) {
        this.rooms -= 1;
      }
    },
    addQuantityRooms() {
      this.rooms += 1;
    },
    edit_search() {
      let fetchedHotel = {
        city_id: this.cityId,
        city_name: this.cityTitle,
        country_code: this.country_code,
        country_name: this.country_title,
        nationality_name: this.nationalityValue.title,
        nationality_id: this.nationalityValue.id,
        nationality_code: this.nationalityValue.code,

        passenger_country_of_residence_code: this.nationalityValue.code,
        no_of_rooms: this.rooms,
        converter_status: 1,
        // test: "test",

        check_in: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
        check_out: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
        rooms_guests: [
          {
            rate_basis: "1",
            adults_count: this.adults,
            children_count: this.childrens,
            children_ages: Array.from(
                {
                  length: this.hotelData == null ? 0 : this.hotelData.childrens,
                },
                () => 7
            ),
          },
        ],
        meta_data: {
          credential_id: 2,
          provider_id: this.provider_id,
        },
        city_title: this.cityTitle,
      };

      localStorage.setItem("hotelDataSearch", JSON.stringify(fetchedHotel));
      this.$router.push("/hotelSearchNew");
    },

    // ScrollUp() {
    //   window.scrollTo({
    //     top: 300,
    //     behavior: "smooth", // Add smooth scrolling behavior
    //   });
    // },

  },
  computed: {
    ...mapState(organizationSettings, [
      "title",
      "email",
      "address",
      "logo",
      "mobliePhone",
      "facebook",
      "whatsapp",
      "instagram",
      "snapchat",
      "tiktok",
      "youtube",
      "twitter",
      "city",
      "country",
    ]),
  },
  async mounted() {
    await this.getSettings(); // Ensure settings are fetched before injecting schema
    await this.fetchHotelDetails();
  },
  created() {
    // console.log(this.hotelQuery)
  },
};
</script>

<style>
.hotel_detials .p-calendar-w-btn .p-inputtext {
  padding: 0.4rem !important;
}

.hotel_detials .form-group .card {
  border-radius: 10px;
}
</style>
