<template>
  <Header />
  <div class="container pt-3 mt-3">
    <Places />
  </div>
  <div class="container">
    <Services/>
  </div>
  <Subscription/>
  <SpecialOffer :sections="sections" />
  <div class="container">
    <Offer v-if="theme == '1'"/>
    <TopPackages v-if="theme == '1'"/>
    <travels v-if="theme == '1'"/>
    <AboutUs v-if="theme == '1'"/>
  </div>
  <!-- <Subscription /> -->
  <div class="container">
    <Testimonials
        v-if="client_opinion.length > 0"
        :client_opinion="client_opinion"
    />
    <News/>
  </div>
  <Application/>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import Header from "@/components/home/Header.vue";
// import Offer from "@/components/home/Offer.vue";
// import Services from "@/components/home/Services.vue";
import Places from "@/components/home/PlacesCrads.vue";
// import Testimonials from "@/components/home/Testimonials.vue";
// import Application from "@/components/home/Application.vue";
// import News from "@/components/home/News.vue";
// import SpecialOffer from "@/components/home/SpecialOffer.vue";
// import TopPackages from "@/components/home/topPackages.vue";
// import travels from "@/components/home/travelsDest.vue";
// import AboutUs from "@/components/home/AboutUs.vue";
import axios from "axios";
// import Subscription from "@/components/home/Subscription.vue";
import {organizationSettings} from "@/store/modules/organizationSettings";
import { mapActions, mapState } from "pinia";
import {hotelsPage} from "@/store/modules/hotelsPage";


export default {
  name: "home-page",
  components: {
    Header,
    AboutUs: defineAsyncComponent(() => import("@/components/home/AboutUs.vue")),
    Services: defineAsyncComponent(() => import("@/components/home/Services.vue")),
    travels: defineAsyncComponent(() => import("@/components/home/travelsDest.vue")),
    SpecialOffer: defineAsyncComponent(() => import("@/components/home/SpecialOffer.vue")),
    Places,
    Testimonials: defineAsyncComponent(() => import("@/components/home/Testimonials.vue")),
    TopPackages: defineAsyncComponent(() => import("@/components/home/topPackages.vue")),
    Application: defineAsyncComponent(() => import("@/components/home/Application.vue")),
    Subscription: defineAsyncComponent(() => import("@/components/home/Subscription.vue")),
    Offer: defineAsyncComponent(() => import("@/components/home/Offer.vue")),
    News: defineAsyncComponent(() => import("@/components/home/News.vue")),
  },

  data() {
    return {
      theme: JSON.parse(localStorage.getItem("Theme")),
      client_opinion: [],
      services: [],
      blogs: [],
      places: [],
      head: {
        title: 'My awesome site'
      }
    };
  },
  methods: {
    ...mapActions(hotelsPage, ["GetHotelsPage"]),

    async fetchClientOpinion() {
      await axios.get("/client_opinion").then(({data}) => {
        this.client_opinion = data.data.data;
      });
    },

    ...mapActions(organizationSettings, ["getSettings"]),

    // Implement Schema
    injectSchema() {
      if (!this.title || !this.logo || !this.mobliePhone) {
        console.warn("Schema data is incomplete. Skipping injection.");
        return;
      }

      const schemaData = {
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": this.title || "Default Name",
        "url": window.location.origin,
        "logo": this.logo || `${window.location.origin}/default-logo.png`,
        "image": [
          this.logo || `${window.location.origin}/default-logo.png`,
        ],
        "description": this.description || "Default description for the travel agency.",
        "telephone": this.mobliePhone || "+0000000000",
        "email": this.email || "default@example.com",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": this.address || "Default Address Street",
          "addressLocality": this.city || "Default Address Locality",
          "addressCountry": this.country || "Default Address country",
          "postalCode": "0000"
        },
        "openingHours": "Mo-Su 09:00-18:00",
        "foundingDate": "2024",
        "founder": {
          "@type": "Person",
          "name": "X-Coders",
        },
        "priceRange": "$$",
        "sameAs": [
          this.facebook,
          this.instagram,
          this.linkedin,
          this.twitter,
          this.snapchat,
          this.tiktok,
          this.youtube,
        ].filter(Boolean), // Remove null or undefined values
        "makesOffer": [
          {
            "@type": "Offer",
            "name": "حجز تذاكر طيران",
            "category": "Flight",
            "url": `${window.location.origin}/flights`,
          },
          {
            "@type": "Offer",
            "name": "حجز فنادق",
            "category": "Hotel",
            "url": `${window.location.origin}/hotels`,
          },
          {
            "@type": "Offer",
            "name": "رحلات الحج والعمرة",
            "category": "Tour",
            "url": `${window.location.origin}/package/umrah`,
          },
          {
            "@type": "Offer",
            "name": "رحلات سياحية",
            "category": "Tour",
            "url": `${window.location.origin}/package/tourism`,
          },
        ],
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": this.mobliePhone || "+0000000000",
          "contactType": "customer service",
          "areaServed": "SA",
          "availableLanguage": ["Arabic", "English", "Indonesia"],
        },
      };

      // Create and inject script
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(schemaData);
      document.head.appendChild(script);
    },
  },
  computed: {
    ...mapState(organizationSettings, [
        "title",
        "email",
        "address",
        "logo",
        "mobliePhone",
        "facebook",
        "whatsapp",
        "instagram",
        "snapchat",
        "tiktok",
        "youtube",
        "twitter",
        "city",
        "country",
      ]),
    ...mapState(hotelsPage, [
      "sections",
    ]),
  },
  async mounted() {
    await this.getSettings(); // Ensure settings are fetched before injecting schema
    await this.GetHotelsPage();
    this.injectSchema();
  },
  async created() {
    // await this.getIP();
    await Promise.all([this.fetchClientOpinion()]);
    localStorage.removeItem("hotelDataSearch");
    localStorage.removeItem("hotelDataFilter");
    localStorage.removeItem("roomData");
    localStorage.removeItem("newBook");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotel_Details");
    localStorage.removeItem("roomPrice");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("hotelMeta");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("flightData");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("packageData");
    sessionStorage.removeItem("searchData");
    sessionStorage.removeItem("packageData");
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./profile/_home.scss";
</style> -->
