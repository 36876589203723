<template>
  <lottie-player
    :src="lottiePlayer"
    background="transparent"
    speed="2"
    loop
    autoplay
  ></lottie-player>
  <div class="d-none"></div>
</template>

<script>
export default {
  name: "loader-component",
  data() {
    return {
      loaders: JSON.parse(localStorage.getItem("loaders"))?.flight_loader,
      lottiePlayer: require("@/assets/lottiefiles/flight_loader.json"),
    };
  },
};
</script>
<!-- 
<style scoped>
@import "./_flightSearchLoading.scss";
</style> -->
