<template>
  <ul class="dropdown-menu coin" v-if="coinCodes.length > 1">
    <li
      v-for="(coin, index) in coinCodes"
      :key="index"
      @click="changeCoinCode(coin.code)"
      :class="coinCodeDisplay == coin.code ? 'active' : ''"
    >
      <div class="d-flex align-items-center">
        <img :src="coin.image" :alt="coin.code" />{{ coin.code }}
      </div>
      <div class="">{{ coin.title }}</div>
    </li>
  </ul>
</template>

<script>
import axios from "axios";
export default {
  // coinCodeDisplay
  data() {
    return {
      coinCodes: [],
      coinCodeDisplay: localStorage.getItem("coinCode")
        ? localStorage.getItem("coinCode")
        : "USD",
    };
  },

  methods: {
    changeCoinCode(coinCode) {
      // this.coinCode = coinCode;
      localStorage.setItem("coinCode", coinCode);
      this.coinCodeDisplay = coinCode;
      this.$emit("coinCodeDisplay", this.coinCodeDisplay);
      this.$store.dispatch("fetchCoin", coinCode);
      // location.reload();
    },
    fetchCurrency() {
      // this.$store.dispatch("fetchCoinCodes");
      axios.post("/organization_currency_website").then(({ data }) => {
        this.coinCodes = data.data.data.map((ele) => {
          return {
            id: ele.id,
            title: ele.title,
            image: ele.image,
            code: ele.code,
            price: ele.price,
            is_master: ele.is_master,
            is_default: ele.is_default,
          };
        });
        // console.log("coinCodes", this.coinCodes)
        localStorage.setItem("coinCodes", JSON.stringify(this.coinCodes));
      });
    },
  },
  mounted() {
    this.fetchCurrency();
  },
};
</script>

<style></style>
