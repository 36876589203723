<template>
  <Header />
  <div class="container mt-3 py-3 mt-lg-5 py-lg-5">
    <flightAirlines v-if="theme === 1" :company_routes="company_routes.data" />
    <travelsDest v-else :company_routes="company_routes.data" />
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import flightAirlines from "@/components/flight/flightAirlines.vue";
import travelsDest from "@/components/home/travelsDest.vue";
import axios from "axios";

export default {
  name: "flightView",
  components: { Header, flightAirlines, travelsDest },
  data() {
    return {
      company_routes: {
        data: [],
      },
      theme: JSON.parse(localStorage.getItem("Theme")),
    };
  },
  methods: {
    async fetchCompanyRoutes() {
      await axios.get(`/flight_company_routes`).then(({ data }) => {
        this.company_routes = data.data;

        // Set the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', this.$t("Flight"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'description';
          newMeta.content = this.$t("Flight");
          document.head.appendChild(newMeta);
        }
        // Set the meta title
        const metaTitle = document.querySelector('meta[name="title"]');
        if (metaTitle) {
          metaTitle.setAttribute('content', this.$t("Flight"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'title';
          newMeta.content = this.$t("Flight");
          document.head.appendChild(newMeta);
        }
        // Update the page title
        const titleEl = document.querySelector("head title");
        if (titleEl) {
          titleEl.textContent = this.$t("Flight");
        } else {
          console.warn("Title element not found!");
        }
      });
    },
  },
  mounted() {
    this.fetchCompanyRoutes();
  },
};
</script>

<style lang="scss" scoped></style>
