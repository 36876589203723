import { createStore } from "vuex";
import axios from "axios";

import authModule from "./modules/auth/index.js";

const store = createStore({
  modules: {
    auth: authModule,
  },

  state: {
    emailUser: "",
    otp_code: "",
    coinCodes: [],
    notifications: [],
    coin: localStorage.getItem("coinCode") ? localStorage.getItem("coinCode") : "SAR",
  },
  mutations: {
    setCoin(state, coin) {
      state.coin = coin;
    },
    setCoinCodes(state, data) {
      state.coinCodes = data;
    },
    SET_Notifications(state, notifications) {
      state.notifications = notifications;
    },
  },
  actions: {
    fetchCoin({ commit }, payload) {
      commit("setCoin", payload);
    },
    async fetchNotifications({ commit, rootState }) {
      // Check if user is authenticated before fetching notifications
      if (rootState.auth.isAuthenticated) {
        try {
          const { data } = await axios.get("/fetch_client_notifications");
          commit("SET_Notifications", data.data.data);
        } catch (error) {
          console.log(error);
        }
      }
    },
    fetchCoinCodes({ commit }) {
      return axios
        .post("/organization_currency_website")
        .then(({ data }) => {
          const coinCodes = data.data.data.map((ele) => ({
            id: ele.id,
            title: ele.title,
            image: ele.image,
            code: ele.code,
            price: ele.price,
          }));
          commit("setCoinCodes", coinCodes);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
  getters: {
    getCoinCodes: (state) => state.coinCodes,
  },
});

export default store;
