<template>
  <Header />
  <section class="umrah_package" v-if="theme === 2">
    <div class="main-omra mt-5">
      <div class="container">
        <div class="header-omra">
          <h4>{{ $t("best_packages") }}</h4>
          <p>
            {{ $t("Travelers_looking_for") }}
          </p>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12"
            v-for="(card, index) in packages.data"
            :key="index"
          >
            <router-link
              :to="`/package_details/${card.id}`"
              class="card umrah_card"
            >
              <img
                :src="card?.main_image"
                class="card-img-top"
                :alt="card.title"
              />
              <span class="Dis_Active" v-if="card.status == 'INACTIVE'">
                {{ $t("INACTIVE") }}</span
              >
              <!-- <span v-if="isTripDateValid(card.trip_date)">Valid Trip Date</span> -->

              <div class="card-body">
                <h5 class="card-title">{{ card.title }}</h5>
                <div class="package_destinations">
                  <span
                    class="destination"
                    v-for="(destination, index) in card.destinations"
                    :key="index"
                    ><i class="fa-solid fa-plane-departure"></i>
                    {{ destination.city?.title }},
                    {{ destination.nights_count }} {{ $t("night") }}</span
                  >
                </div>
                <div class="package_service">
                  <span
                    class="service"
                    v-for="(service, index) in card.package_services"
                    :key="index"
                  >
                    <div class="icon">
                      <i
                        class="fa-solid"
                        :class="{
                          'fa-hotel': service == 'Hotel',
                          'fa-plane-departure': service == 'Flight',
                          'fa-passport': service == 'Visa',
                          'fa-car': service == 'Transportation',
                          'fa-shield': service == 'Insurance',
                        }"
                      ></i>
                    </div>
                    {{ $t(`${service}`) }}
                  </span>
                </div>
                <div class="card_footer">
                  <div class="price">
                    <p>{{ $t("start from") }}</p>
                    <p>
                      {{ convertedPrice(card.base_price.adult) }} {{ coinCode }}
                    </p>
                  </div>
                  <router-link
                    :to="`/package_details/${card.id}`"
                    class="btn show_details"
                    >{{ $t("show details") }}</router-link
                  >
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row">
          <pagination
            :data="packages"
            class="mx-auto d-flex align-items-center justify-content-center pagination"
            @pagination-change-page="fetchPackageByCity"
          >
            <template v-slot:prev-nav>&lt;</template>
            <template v-slot:next-nav>&gt;</template>
          </pagination>
        </div>
      </div>
    </div>
  </section>
  <div class="container mt-5 pt-2" v-else>
    <topPackages />
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/home/Header.vue";
import topPackages from "@/components/home/topPackages.vue";
import pagination from "laravel-vue-pagination";

export default {
  name: "umrah-package",
  data() {
    return {
      blogs: [],
      theme: JSON.parse(localStorage.getItem("Theme")),
      countries: [],
      packages: {
        data: [],
      },
      coinCode:
          localStorage.getItem("coinCode")
              ? localStorage.getItem("coinCode")
              : JSON.parse(localStorage.getItem("coinCodes")) ? JSON.parse(localStorage.getItem("coinCodes"))[0]?.title : "SAR",
      dataToday: new Date(),
    };
  },
  components: {
    pagination,
    Header,
    topPackages,
  },
  methods: {
    // async fetchHomeData() {
    //   await axios.get("/web_site_homes").then((data) => {
    //     this.blogs = data.data.data.blog.slice(0, 3);
    //   });
    // },

    fetchPackageByCity(page = 1) {
      const formData = new FormData();
      if (this.$route.params.type === "umrah") {
        formData.append("country_id", 598);
      }
      formData.append(
        "package_tourism_type",
        this.$route.params.type === "umrah" ? 1 : 2
      );

      axios
        .post(`/fetch_package_by_country_id?page=${page}`, formData)
        .then((data) => {
          this.packages = data.data.data;

          if(this.$route.params.type === "umrah") {
            // Set the meta description
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
              metaDescription.setAttribute('content', this.$t("UMRAH PACKAGES"));
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'description';
              newMeta.content = this.$t("UMRAH PACKAGES");
              document.head.appendChild(newMeta);
            }
            // Set the meta title
            const metaTitle = document.querySelector('meta[name="title"]');
            if (metaTitle) {
              metaTitle.setAttribute('content', this.$t("UMRAH PACKAGES"));
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'title';
              newMeta.content = this.$t("UMRAH PACKAGES");
              document.head.appendChild(newMeta);
            }
            // Update the page title
            const titleEl = document.querySelector("head title");
            if (titleEl) {
              titleEl.textContent = this.$t("UMRAH PACKAGES");
            } else {
              console.warn("Title element not found!");
            }
          }

          // type tourism
          if(this.$route.params.type === "tourism") {
            // Set the meta description
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
              metaDescription.setAttribute('content', this.$t("TravelPackage"));
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'description';
              newMeta.content = this.$t("TravelPackage");
              document.head.appendChild(newMeta);
            }
            // Set the meta title
            const metaTitle = document.querySelector('meta[name="title"]');
            if (metaTitle) {
              metaTitle.setAttribute('content', this.$t("TravelPackage"));
            } else {
              // If the meta tag doesn't exist, create one
              const newMeta = document.createElement('meta');
              newMeta.name = 'title';
              newMeta.content = this.$t("TravelPackage");
              document.head.appendChild(newMeta);
            }
            // Update the page title
            const titleEl = document.querySelector("head title");
            if (titleEl) {
              titleEl.textContent = this.$t("TravelPackage");
            } else {
              console.warn("Title element not found!");
            }
          }

        });
    },

    // isTripDateValid(tripDate) {
    //   const today = new Date();
    //   const tripDateObject = new Date(tripDate);

    //   // Compare tripDate with today's date
    //   console.log(today)
    //   console.log(tripDateObject)
    //   return tripDateObject < today;
    // }
  },
  mounted() {
    // this.fetchHomeData();
    // this.fetchPackageCountries();
    this.fetchPackageByCity();
    // console.log(this.$route.params.type);
    sessionStorage.removeItem("searchData");
    sessionStorage.removeItem("packageData");
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./_umrah.scss";

</style> -->
