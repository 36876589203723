<template>
  <section
      :class="{
      flight: path == '/flights',
      hotels: path == '/hotels',
      cardSale: path == '/cardSale',
      umrah: path == '/package/umrah' || '/umrahSearch',
      tourism: path == '/package/tourism',
    }"

      class="main_header"
  >
     <img rel="preload" :src="path === '/'
          ? headers.header_image
          : path === '/hotels'
          ? headers.header_hotel_image
          : path === '/flights'
          ? headers.header_flight_image
          : path === '/cardSale'
          ? headers.header_offer_image
          : path === '/package/umrah' || '/umrahSearch'
          ? headers.header_umrah_image
          : path === '/package/tourism'
          ? headers.header_umrah_image
          : headers.header_image" class="background_header" alt="Header">

    <div class="content">
      <div class="content-text">
        <!-- Title -->
        <h1 class="text-end">
          {{
            path == "/hotels"
                ? title_header_hotels
                : path == "/flights"
                    ? title_header_flights
                    : path == "/"
                        ? title_header_generals
                        : path == "/umrah_package"
                            ? title_header_packages
                            : path == "/cardSale"
                                ? title_header_offers
                                : title_header_hotels
          }}
        </h1>

        <!-- Description -->
        <p
            class="text-primary"
            v-html="
            path == '/hotels'
              ? text_header_hotels
              : path == '/flights'
              ? text_header_flights
              : path == '/'
              ? text_header_generals
              : path == '/umrah_package'
              ? text_header_packages
              : path == '/cardSale'
              ? text_header_offers
              : text_header_hotels
          "
        ></p>
      </div>
      <div class="reservations">
        <ul class="reservations_filters">
          <template v-for="(service, index) in sortedServices" :key="index">
            <li
                v-if="service.service_name === 'HOTEL'"
                :class="{ active: reservation_active == 'Hotels' }"
                @click="reservation_active = 'Hotels'"
            >
              {{ $t("Hotels") }} <i class="fa-solid fa-hotel"></i>
            </li>
            <li
                v-if="service.service_name === 'FLIGHT'"
                :class="{ active: reservation_active == 'Flight' }"
                @click="reservation_active = 'Flight'"
            >
              {{ $t("Flight") }} <i class="fa-solid fa-plane-departure"></i>
            </li>
            <li
                v-if="service.service_name === 'PACKAGE'"
                :class="{ active: reservation_active == 'umrah' }"
                @click="reservation_active = 'umrah'"
            >
              {{ $t("UMRAH PACKAGES") }} <i class="fa-solid fa-kaaba"></i>
            </li>
            <li
                v-if="service.service_name === 'TravelPackage'"
                :class="{ active: reservation_active == 'tourism' }"
                @click="reservation_active = 'tourism'"
            >
              {{ $t("TravelPackage") }} <i class="fa-solid fa-kaaba"></i>
            </li>
          </template>
        </ul>

        <div v-show="reservation_active === 'Hotels'" class="data">
          <div class="selects hotelSelect row mb-0">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <img class="icon" alt="location" src="@/assets/media/image/location.svg" loading="lazy"/>
                <input
                    v-model="searchValue"
                    :placeholder="$t('choose_city')"
                    class="form-control"
                    type="search"
                    @focus="focusHotels"
                    @input="debouncedSearchHotels"
                    @focusout="handleFocusOutHotel"
                />
                <div
                    v-if="
                    citiesSearch.length && focusSearch
                  "
                    class="resultSearch"
                >
                  <h6
                      class="head_search d-flex justify-content-between align-items-center text-white"
                  >
                    <span>{{ $t("The Cities") }}</span>
                    <span class="cursor-pointer" @click="focusSearch = false; citiesSearch = []"
                    >X</span
                    >
                  </h6>
                  <ul class="cities">
                    <li
                        v-for="(city, index) in citiesSearch"
                        :key="index"
                        @click="
                        sendCityId(
                          city.title,
                          city.id,
                          city.country_code,
                          city.country_title
                        )
                      "
                    >
                      <i class="fa-solid fa-location-dot"></i>
                      <div class="texts">
                        <p class="title">{{ city.title }}</p>
                        <p class="country">{{ city.country_title }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-2">
              <div class="form-group">
                <img
                    class="icon"
                    alt="global-search"
                    src="@/assets/media/image/global-search.png"
                />
                <input
                    v-model="nationalityValue"
                    :placeholder="$t('choose_nationality_country')"
                    class="form-control"
                    type="search"
                    @focus="focusNationality"
                    @input="debouncedSearchNationality"
                    @focusout="handleFocusOutNationality"
                />
                <div v-if="nationalities?.length && focusSearchNationality" class="resultSearch">
                  <h6 class="head_search text-white">
                    {{ $t("The Nationalities") }}
                  </h6>
                  <ul class="nationalities">
                    <li
                        v-for="(nationality, index) in nationalities"
                        :key="index"
                        @click="
                        sendNationalId(
                          nationality.id,
                          nationality.title,
                          nationality.code
                        )
                      "
                    >
                      <i class="fa-solid fa-location-dot"></i>
                      <div class="texts">
                        <p class="title">{{ nationality.title }}</p>
                        <p class="country">{{ nationality.code }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <div class="w-100">
                  <Calendar
                      v-model="selectedDate"
                      :minDate="new Date()"
                      class="w-100"
                      dateFormat="dd/mm/yy"
                      selectionMode="range"
                      ariaLabel="Choose an dates"
                      showIcon
                      @dateSelect="closeCalendar"
                      @open="openCalendar"
                      :responsiveOptions="[
                        {
                          breakpoint: '1024px',
                          numMonths: 2,
                        },
                        {
                          breakpoint: '768px',
                          numMonths: 1,
                        },
                      ]"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-2">
              <div class="form-group">
                <div class="dropdown w-100">
                  <button
                      aria-expanded="false"
                      class="btn btn-primary dropdown-toggle"
                      data-bs-auto-close="outside"
                      data-bs-toggle="dropdown"
                      type="button"
                  >
                    {{ $t("rooms") }} {{ rooms.length }} - {{ $t("Adults") }}
                    {{ totalAdults }} - {{ $t("childrens") }}
                    {{ totalChildrens }}
                  </button>
                  <div class="dropdown-menu">
                    <div
                        v-for="(room, item) in rooms"
                        :key="item"
                        class="rooms"
                    >
                      <h6 class="room">{{ $t("room") }} {{ item + 1 }}</h6>
                      <div class="list">
                        <span>{{ $t("Adults") }}</span>
                        <div class="number">
                          <button
                              :disabled="room.adults_count <= 1"
                              class="btn minus"
                              type="button"
                              @click="minusQuantityAdults(item)"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ room.adults_count }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityAdults(item)"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="list">
                        <span>{{ $t("childrens") }}</span>
                        <div class="number">
                          <button
                              :disabled="room.children_count <= 0"
                              class="btn minus"
                              type="button"
                              @click="minusQuantityChildrens(item)"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <p class="count">{{ room.children_count }}</p>
                          <button
                              class="btn add"
                              type="button"
                              @click="addQuantityChildrens(item)"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="childrens_ages">
                        <div
                            v-for="(age, itemAge) in room.children_count"
                            :key="itemAge"
                            class="form-group"
                        >
                          <input
                              id=""
                              v-model="room.children_ages[itemAge]"
                              :placeholder="$t('age')"
                              class="form-control"
                              name=""
                              min="1"
                              type="number"
                          />
                        </div>
                      </div>
                      <button
                          :disabled="item <= 0"
                          class="btn btn-danger minus-btn"
                          type="button"
                          @click="minusQuantityRooms(item)"
                      >
                        <i class="fa-regular fa-trash-can"></i>
                        {{ $t("delete") }}
                      </button>
                    </div>
                    <button
                        class="btn btn_add_room"
                        type="button"
                        @click="addQuantityRooms()"
                    >
                      <i class="fa-solid fa-plus"></i>
                      {{ $t("add_room") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-12">
              <button
                  class="btn SecondaryButton"
                  type="button"
                  @click="sendHotelData"
              >
                {{ $t("search now") }}
              </button>
            </div>
          </div>
        </div>
        <div v-show="reservation_active == 'Flight'" class="data pt-0">
          <flightTabs/>
        </div>
        <div v-show="reservation_active == 'umrah'" class="data">
          <umraSearchHeader
              :dataUmrah="dataUmrah"
              :reservation_active="reservation_active"
              @fetchData="fetchData"
              @sendPackageData="DataFromHeaderSearch"
          />
        </div>
        <div v-show="reservation_active == 'tourism'" class="data">
          <umraSearchHeader
              :dataUmrah="dataUmrah"
              :reservation_active="reservation_active"
              @fetchData="fetchData"
              @sendPackageData="DataFromHeaderSearch"
          />
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import flightTabs from "@/components/flight/flightTabs.vue";
import Calendar from "primevue/calendar";
import umraSearchHeader from "@/components/omraSearch/OmraSearch.vue";
import "primevue/resources/themes/lara-light-teal/theme.css";
import Swal from "sweetalert2";
// import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import {organizationSettings} from "@/store/modules/organizationSettings";
import {mapState} from "pinia";

export default {
  name: "Header-layout",
  data() {
    return {
      focusSearch: false,
      focusSearchNationality: false,
      text_header_hotels: "",
      title_header_hotels: "",
      text_header_flights: "",
      title_header_packages: "",
      text_header_packages: "",
      title_header_flights: "",
      text_header_generals: "",
      title_header_generals: "",
      text_header_offers: "",
      title_header_offers: "",
      header_text: [],
      webPath: "https://kanaya.x-coders.net", //window.location.origin,
      places: [
        {
          id: 1,
          name: "cairo",
          // image: ""
        },
      ],
      dataUmrah: {},
      placeValue: "",
      reservation_active: "",
      hotels: [],
      countries: [],
      countryValue: "",
      searchValue:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? ""
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.hotel_name ==
              null
                  ? JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_title
                  : JSON.parse(localStorage.getItem("hotelDataSearch"))?.hotel_name,
      cityValue: [],
      nationalities: [],
      nationalityValue: "",
      hotelsSearch: [],
      citiesSearch: [],
      cityId: "",
      hotelValue: "",
      nationalityName: "",
      nationalityID: "",
      nationalityCode: "",
      nationalityTitle: "",
      hotelID: "",
      selectedDate: [
        new Date(),
        new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      ],

      adults:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 2
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.adults,
      childrens:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
              ? 0
              : JSON.parse(localStorage.getItem("hotelDataSearch"))?.childrens,
      rooms: [
        {
          rate_basis: "1",
          adults_count: 2,
          children_count: 0,
          children_ages: [],
        },
      ],
      totalAdults: this.totalAdults == null ? 2 : this.totalAdults,
      totalChildrens: this.totalChildrens == null ? 0 : this.totalChildrens,
      searchValueLaunch: "",
      searchValueReturn: "",
      fetchFlightsFunction: null, // Initialize as null
      // loading: false,
      path: this.$route.path,
      headers: {},
      isError: false,
      debounceTimeout: null,
      isRequestPending: false,
      hotelSearchLoading: false,
      debounceTimeoutNationality: null,
      isRequestPendingNationality: false,
      NationalitySearchLoading: false,
    };
  },
  computed: {
    ...mapState(organizationSettings, [
      "services",
      "website_hotel_request_type",
    ]),
    sortedServices() {
      const order = ["HOTEL", "FLIGHT", "PACKAGE", "TravelPackage"];
      return this.services.slice().sort((a, b) => {
        return order.indexOf(a.service_name) - order.indexOf(b.service_name);
      });
    },
  },
  components: {
    umraSearchHeader,
    // Datepicker: VueDatepickerUi,
    flightTabs,
    Calendar,
  },
  methods: {
    focusHotels() {
      this.focusSearch = true;
      // this.nationalities = []
      // this.searchHotels();
    },
    focusNationality() {
      this.focusSearch = false;
      this.focusSearchNationality = true;
      this.searchNationality();
    },
    fetchText_header() {
      axios.get("/show_header").then((res) => {
        // this.text_header_hotels = res.data.data.data.text_header_hotels;
        // this.text_header_flights = res.data.data.data.text_header_flights;
        // this.text_header_packages = res.data.data.data.text_header_packages;
        this.header_text = res.data.data;
        this.header_text.forEach((element) => {
          if (element.service == 1) {
            this.title_header_hotels = element.title;
            this.text_header_hotels = element.sub_title;
          }
          if (element.service == 2) {
            this.title_header_flights = element.title;
            this.text_header_flights = element.sub_title;
          }
          if (element.service == 5) {
            this.title_header_packages = element.title;
            this.text_header_packages = element.sub_title;
          }
          if (element.service == 6) {
            this.title_header_generals = element.title;
            this.text_header_generals = element.sub_title;
          }
          if (element.service == 7) {
            this.title_header_offers = element.title;
            this.text_header_offers = element.sub_title;
          }
        });
        // console.log(this.header_text, "header");
      });
    },

    // Debounce function with character length check
    debouncedSearchNationality(event) {
      if (this.debounceTimeoutNationality) {
        clearTimeout(this.debounceTimeoutNationality);
      }

      this.debounceTimeoutNationality = setTimeout(() => {
        if (event.target.value.length <= 2) {
          return; // Exit if the input length is 2 or less
        }
        this.searchNationality(event);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },

    searchNationality(event) {
      // if (this.isRequestPendingNationality) return;
      this.NationalitySearchLoading = true;
      this.focusSearch = false;
      // this.isRequestPendingNationality = true;
      const searchText = {word: event?.target?.value};

      axios
          .post(`/SearchNationality`, searchText)
          .then((res) => (this.nationalities = res.data.data))
          .catch((err) => console.log(err));
      // console.log(this.nationalities);
    },

    // Debounce function with character length check
    debouncedSearchHotels(event) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        if (event.target.value.length <= 2) {
          return; // Exit if the input length is 2 or less
        }
        this.searchHotels(event);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },

    searchHotels(event) {
      this.hotelSearchLoading = true;
      const searchText = {word: event?.target?.value};

      // website_hotel_request_type 1 = offline, 2 = online
      axios
          .post(
              this.website_hotel_request_type === 2
                  ? `/search_home_hotels`
                  : `/fetch_offline_home_hotels`,
              searchText
          )
          .then(
              (res) => (
                  (this.hotelsSearch = res.data.data.hotels),
                      (this.citiesSearch = res.data.data.cities)
              )
          )
          .catch((err) => console.log(err));

      // console.log(searchText);
    },
    handleFocusOutHotel() {
      setTimeout(() => {
        this.focusSearch = false;
      }, 200); // Delay to allow click event to finish
    },
    handleFocusOutNationality() {
      setTimeout(() => {
        this.focusSearchNationality = false;
      }, 200); // Delay to allow click event to finish
    },

    // fetch hotels
    // sendHotelName(hotelName, hotelID, cityId, cityTitle, country_code, country_title) {
    //   this.hotelName = hotelName;
    //   this.hotelID = hotelID;
    //   this.cityTitle = cityTitle;
    //   this.searchValue = hotelName;
    //   this.cityId = cityId;
    //   this.country_code = country_code;
    //   this.country_title = country_title;
    //   this.citiesSearch.length = [];
    //   this.hotelsSearch = [];
    //   // console.log(this.hotelName)
    //   // console.log(this.hotelID)
    // },
    sendCityId(cityTitle, cityId, country_code, country_title) {
      // console.log("cityTitle, cityId, country_code, country_title");
      this.cityId = cityId;
      this.cityTitle = cityTitle;
      this.searchValue = cityTitle;
      this.country_code = country_code;
      this.country_title = country_title;
      this.hotelName = null;
      this.hotelID = null;
      // this.citiesSearch = [];
      // this.hotelsSearch = [];
      // this.focusHotels();
      // console.log(this.cityTitle)
      // console.log(this.cityId)
    },
    sendNationalId(id, title, code) {
      this.nationalityID = id;
      this.nationalityTitle = title;
      this.nationalityCode = code;
      this.nationalityValue = title;
      // this.nationalities = [];
    },
    DataFromHeaderSearch(data) {
      // Handling the data emitted by the child component
      this.dataUmrah = data;
      console.log(this.dataUmrah);
    },
    fetchData() {
      this.$emit("fetchData");
    },

    // Adults Minus && Add
    addQuantityAdults(item) {
      this.rooms[item].adults_count += 1;
      this.totalAdults = 0;

      // Iterate through each room and add the number of adults to the total
      this.rooms.forEach((room) => {
        this.totalAdults += room.adults_count;
      });
    },

    minusQuantityAdults(item) {
      if (this.rooms[item].adults_count > 1) {
        this.rooms[item].adults_count -= 1;
      }

      this.totalAdults = 0;

      // Iterate through each room and add the number of adults to the total
      this.rooms.forEach((room) => {
        this.totalAdults += room.adults_count;
      });
    },

    // childrens Minus && Add
    minusQuantityChildrens(item) {
      if (this.rooms[item].children_count > 0) {
        this.rooms[item].children_count -= 1;
      }

      this.totalChildrens = 0;

      // Iterate through each room and add the number of adults to the total
      this.rooms.forEach((room) => {
        this.totalChildrens += room.children_count;
      });
    },
    addQuantityChildrens(item) {
      this.rooms[item].children_count += 1;
      this.rooms[item].children_ages.push("");

      this.totalChildrens = 0;

      // Iterate through each room and add the number of adults to the total
      this.rooms.forEach((room) => {
        this.totalChildrens += room.children_count;
      });
    },

    // Rooms Minus && Add
    minusQuantityRooms(index) {
      // Remove the room at the specified index from the rooms array
      this.rooms.splice(index, 1);
    },
    addQuantityRooms() {
      // Create a new room with default values and push it to the rooms array
      const newRoom = {
        rate_basis: "1",
        adults_count: 2,
        children_count: 0,
        children_ages: [],
      };
      this.rooms.push(newRoom);
    },
    closeCalendar(){
      console.log(this.selectedDate)
      if(this.selectedDate[1]) {
        document.querySelector('.p-datepicker').style.display = 'none';
      }
    },

    openCalendar(){
      document.querySelector('.p-datepicker').style.display = 'block';
    },
    // send data to parent component
    sendHotelData() {
      const dataObject = {
        city_id: this.cityId,
        city_name: this.cityTitle,
        country_code: this.country_code,
        country_name: this.country_title,
        // hotel_name: this.hotelName,
        // hotel_code: this.hotelID,
        nationality_name: this.nationalityTitle,
        nationality_id: this.nationalityID,
        nationality_code: this.nationalityCode,
        passenger_country_of_residence_code: this.nationalityCode,
        no_of_rooms: this.rooms.length,
        converter_status: 1,
        test: "test",
        check_in:
            this.selectedDate != null
                ? this.selectedDate[0] != null
                    ? moment(this.selectedDate[0]).format("YYYY-MM-DD")
                    : ""
                : "",
        // check_in_time: moment(this.selectedDate[0]).format("h:mm:ss a"),
        check_out:
            this.selectedDate != null
                ? this.selectedDate[1] != null
                    ? moment(this.selectedDate[1]).format("YYYY-MM-DD")
                    : ""
                : "",
        // check_out_time: moment(this.selectedDate[1]).format("h:mm:ss a"),
        rooms_guests: this.rooms,
        // rooms_guests: [
        //   {
        //     rate_basis: "1",
        //     adults_count: this.adults,
        //     children_count: this.childrens,
        //     children_ages: Array.from(
        //       {
        //         length: this.hotelData == null ? 0 : this.hotelData.childrens,
        //       },
        //       () => 7
        //     ),
        //   },
        // ],
        // room_guests: this.rooms,
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
      };

      if (dataObject.city_id == "" || dataObject.city_id == undefined) {
        Swal.fire({
          title: "",
          text: this.$t("choose hotel or city"),
          icon: "error",
        });
      } else if (
          dataObject.nationality_id == "" ||
          dataObject.nationality_id == undefined
      ) {
        Swal.fire({
          title: "",
          text: this.$t("Choose nationality first"),
          icon: "error",
        });
      } else if (dataObject.check_in == "" || dataObject.check_in == null) {
        Swal.fire({
          title: "",
          text: this.$t("choose check_in"),
          icon: "error",
        });
      } else if (dataObject.check_out == "" || dataObject.check_out == null) {
        Swal.fire({
          title: "",
          text: this.$t("choose check_out"),
          icon: "error",
        });
      } else {
        localStorage.setItem("hotelDataSearch", JSON.stringify(dataObject));
        this.$router.push("/hotelSearchNew");
      }
    },
    // runFlight() {
    //   this.$emit("runFlight");
    // },

    webSiteHeaders() {
      axios
          .get("/fetch_web_site_headers")
          .then(({data}) => {
            this.headers = data.data;
            // console.log(this.headers);
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
    },

    setDefaultImage(event) {
      event.target.src = localStorage.getItem("DefaultImage");
      this.isError = true; // Set isError to true when an error occurs
    },
  },
  created() {
    this.webSiteHeaders();

    const path = this.$route.path;

    // Simplify route checks with a lookup table
    const routeMap = {
      "/": "Hotels",
      "/hotels": "Hotels",
      "/cardSale": "Hotels",
      "/flights": "Flight",
      "/package/umrah": "umrah",
      "/umrahSearch": "umrah",
      "/package/tourism": "tourism",
    };

    this.reservation_active = routeMap[path] || null;

    // Fetch data in parallel
    Promise.all([this.fetchText_header(), this.searchHotels()])
        .then(() => {
          // console.log("Header text and hotel search data fetched.");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
  },
  watch: {
    "$route.params.type": {
      handler: function () {
        this.reservation_active = this.$route.params.type;
      },
      immediate: true,
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
