<template>
  <div class="container mt-4">
    <div class="selects hotelSelect row">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <!-- <img class="icon" src="@/assets/media/image/icon/address.png" /> -->
          <Multiselect
            v-model="country_value"
            :options="countries"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('What is your destination ?')"
            select-label=""
            :hide-selected="true"
            @keyup="searchCountries"
          >
          </Multiselect>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-2">
        <div class="form-group">
          <!-- <img class="icon" src="@/assets/media/image/icon/nationality.png" /> -->
          <Multiselect
            v-model="nationalityValue"
            :options="nationalities"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_nationality')"
            select-label=""
            :hide-selected="true"
            @keyup="searchNationality"
          >
          </Multiselect>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <div class="card flex justify-content-center">
            <Calendar
              v-model="selectedDate"
              selectionMode="range"
              :manualInput="true"
              :minDate="new Date()"
              dateFormat="dd/mm/yy"
              ariaLabel="Choose an dates"
              showIcon
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-2">
        <div class="form-group">
          <Multiselect
            v-model="package_type_id"
            :options="packages"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('package_type')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <button
          type="button"
          @click="sendPackageData"
          class="btn PrimaryButton search"
        >
          {{ $t("search now") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Calendar from "primevue/calendar";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      nationalities: [],
      nationalityValue: "",
      citiesSearch: [],
      selectedDate: null,
      packages: [],
      package_type_id: "",
      searchValue: "",
      countries: [],
      country_value: {},
      package_tourism_type: this.$route.params.type
    };
  },
  props: {
    dataUmrah: Object,
  },
  methods: {
    searchCountries(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.countries = res.data.data.data))
        .catch((err) => console.log(err));
    },

    searchPackages() {
      this.package_type = {package_type: this.package_tourism_type == 'tourism' ? 2 : 1}
      axios
        .post(`/fetch_package_types`, this.package_type)
        .then((res) => (this.packages = res.data.data))
        .catch((err) => console.log(err));
    },

    searchCity(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/search_home_hotels`, searchText)
        .then((res) => (this.citiesSearch = res.data.data.cities))
        .catch((err) => console.log(err));
      // console.log(searchText);
    },

    sendCityId(city_id) {
      this.city_id = city_id;
      this.citiesSearch = [];
      // console.log(this.cityTitle)
      // console.log(this.cityId)
    },
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.nationalities);
    },
    validateFormData() {
      if (!this.country_value) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("please_select_country"),
        });
        return false;
      }
      if (!this.nationalityValue) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("please_select_nationality"),
        });
        return false;
      }
      if (!this.selectedDate) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("please_select_date"),
        });
        return false;
      }
      if (!this.package_type_id) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("please_select_package"),
        });
        return false;
      }
      return true;
    },
    sendPackageData() {
      if (this.validateFormData()) {
        const packageData = {
          country_id: this.country_value.id,
          // city_id: this.country_value.id,
          from_date: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
          package_type_id: this.package_type_id.id,
        };
        const umrahData = {
          package: this.package_type_id,
          country_id: this.country_value.id,
          country_value: this.country_value,
          nationalityValue: this.nationalityValue,
          from_date: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
        };
        sessionStorage.setItem("packageData", JSON.stringify(packageData));
        sessionStorage.setItem("searchData", JSON.stringify(umrahData));
        this.$router.push("/umrahSearch");
        this.$emit("fetchData");
      }
    },
    fetch_search_data() {
      let searchData =
        JSON.parse(sessionStorage.getItem("searchData")) == null
          ? {}
          : JSON.parse(sessionStorage.getItem("searchData"));
      this.package_type_id = searchData.package;
      this.country_value = searchData.country_value;
      // this.city_id = searchData.city_id;
      this.country_id = searchData.country_id;
      this.nationalityValue = searchData.nationalityValue;
      this.selectedDate =
        JSON.parse(sessionStorage.getItem("searchData")) == null
          ? null
          : [
              JSON.parse(sessionStorage.getItem("searchData")).from_date,
              JSON.parse(sessionStorage.getItem("searchData")).to_date,
            ];
    },
  },
  created() {
    this.searchPackages();
  },
  mounted() {
    this.fetch_search_data();
  },
  components: {
    Calendar,
    Multiselect,
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
// @import "@/components/home/_header.scss";
.selects {
  padding-top: 7rem;
}
</style> -->
