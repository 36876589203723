<script setup>
import Dialog from 'primevue/dialog';
import Calendar from "primevue/calendar";
import {organizationSettings} from "@/store/modules/organizationSettings";
import moment from "moment";

import {computed,  ref} from "vue";
import Multiselect from "vue-multiselect";
import axios from "axios";

const visible = ref(false);

const hotels = ref([]);
const hotel = ref({});
const rooms = ref([]);
const cities = ref([]);
const room = ref({});
const focusSearch = ref(false);
const debounceTimeout = ref(null);
const citiesSearchLoading = ref(false);
const cityTitle = ref("");
const countryTitle = ref("");
const guest_count = ref(1);
// const word = ref({});
const showNumberOfGuest = ref(false)
const showRooms = ref(false)
const fromDate = ref(new Date());
const toDate = ref(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
const organizationSettingsStore = organizationSettings();
const searchValue = ref("");

const minusQuantityGuests = () => {
  if (guest_count.value > 1) {
    guest_count.value -= 1;
  }
}
const addQuantityGuests = () => {
  guest_count.value += 1;
}
const fetchContract = (hotel) => {
  showRooms.value = true;
  // console.log("hotel test", hotel.id)
  axios
      .post(`/fetch_rooms_upon_hotel`, {
        hotel_id: hotel?.id,
      })
      .then((res) => {
        rooms.value = res.data.data;
      });
};

// Debounce function with character length check
const debouncedSearchCities = (event) => {
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }

  debounceTimeout.value = setTimeout(() => {
    if (event.target.value.length <= 2) {
      return; // Exit if the input length is 2 or less
    }
    fetchCitiesHotels(event);
  }, 1000); // Adjust the delay as needed (e.g., 1000ms)
};


const website_hotel_request_type = computed(() => organizationSettingsStore.website_hotel_request_type)

const fetchCitiesHotels = (event) => {
  citiesSearchLoading.value = true;
  // focusSearch.value = true;

  // website_hotel_request_type 1 = offline, 2 = online
  axios
      .post(website_hotel_request_type.value === 2 ? `/search_home_hotels` : `/fetch_offline_home_hotels`, {
        word: event ? event?.target?.value : "",
      })
      .then((res) => {
        cities.value = res.data.data.cities;
        hotels.value = res.data.data.hotels;
      });
};

const sendCityId = (city_title, country_title) => {
  cityTitle.value = city_title;
  countryTitle.value = country_title;
  searchValue.value = city_title;
  focusSearch.value = false;
  showNumberOfGuest.value = true;
  showRooms.value = false;
};

const sendHotelName = (hotel, hotel_name, city_title, country_title) => {
  // console.log("hotel", hotel)
  hotel.value = hotel_name;
  searchValue.value = hotel_name;
  cityTitle.value = city_title;
  countryTitle.value = country_title;
  focusSearch.value = false;
  showNumberOfGuest.value = false;
  showRooms.value = true;
  fetchContract(hotel);
}

const whatsapp = computed(() => organizationSettingsStore.whatsapp)

const whatsappLink = computed(() => {
  const messageHotel = `اريد حجز ${room.value.name} غرفة\n` +
      `فندق : ${hotel.value?.title}\n` +
      ` مدينة : ${cityTitle.value}\n` +
      `تاريخ الدخول: ${moment(fromDate.value).format("YYYY-MM-DD")}\n` +
      `تاريخ الخروج: ${moment(toDate.value).format("YYYY-MM-DD")}\n`;

  const messageCity = `اريد حجز ${guest_count.value} شخض \n` +
      ` لفندق في مدينة : ${cityTitle.value}, ${countryTitle.value}\n` +
      ` تاريخ الدخول: ${moment(fromDate.value).format("YYYY-MM-DD")}\n` +
      `تاريخ الخروج:  ${moment(toDate.value).format("YYYY-MM-DD")}\n`

  return `https://wa.me/${whatsapp.value}?text=${encodeURIComponent(hotel.value?.id ? messageHotel : messageCity)}`
})

const closeDialog = () => {
  visible.value = false;
  hotel.value = {};
  searchValue.value = "";
  room.value = {};
  guest_count.value = {};
}

</script>

<template>
  <div :tooltip="$t('quick_booking')" class="layout" flow="up" @click="visible = true;fetchCitiesHotels()">
    <i class="fa-solid fa-clipboard"></i>
  </div>

  <Dialog v-model:visible="visible" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :dismissableMask="true"
          :header="($t('quick_booking'))" :style="{ width: '50rem' }"
          class="w-50" modal>
    <div class="row">
      <div class="col-md-6 col-12 selects mb-3">
        <div class="form-group">
          <label>
            {{ $t("select_hotel") }} / {{ $t("choose_city") }}
          </label>
          <input
              v-model="searchValue"
              :placeholder="$t('choose_city')"
              class="form-control px-2"
              type="search"
              @input="debouncedSearchCities"
              @focus="focusSearch = true"
          />
          <div
              v-if="focusSearch"
              class="resultSearch">

            <h6 class="head_search d-flex justify-content-between align-items-center text-white">
              <span>{{ $t("The Cities") }}</span>
              <span class="cursor-pointer" @click="focusSearch = false">X</span>
            </h6>
            <ul class="cities">
              <li
                  v-for="(city, index) in cities"
                  :key="index"
                  @click="
                        sendCityId(
                          city.title,
                          city.country_title
                        )
                      "
              >
                <i class="fa-solid fa-location-dot"></i>
                <div class="texts">
                  <p class="title">{{ city.title }}</p>
                  <p class="country">{{ city.country_title }}</p>
                </div>
              </li>
            </ul>
            <h6 class="head_search">{{ $t("Hotels") }}</h6>
            <ul class="hotels">
              <li
                  v-for="(hotel, index) in hotels"
                  :key="index"
                  @click="
                          sendHotelName(
                            hotel,
                            hotel.title,
                            hotel.city_title,
                            hotel.country_title
                          )
                        "
              >
                <img
                    :alt="hotel.title"
                    :src="hotel.image"
                    class="hotel_image"
                />
                <div class="texts">
                  <p class="title">{{ hotel.title }}</p>
                  <p class="country">
                    {{ hotel.country_title }} , {{ hotel.city_title }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 selects mb-3" v-if="showRooms">
        <div class="form-group">
          <label>
            {{ $t("rooms") }}
          </label>
          <Multiselect v-model="room" :clear-on-select="true" :hide-selected="true" :options="rooms"
                       :placeholder="$t('rooms')"
                       label="name" select-label="" track-by="id"></Multiselect>
        </div>
      </div>
      <div class="col-md-6 col-12 selects mb-3" v-if="showNumberOfGuest">
        <div class="form-group">
          <label>
            {{ $t("guests") }}
          </label>
          <div class="counter">
            <div class="d-flex">
              <div
                  class="counter-btn right"
                  @click="minusQuantityGuests()"
              >
                <i class="fa-solid fa-minus"></i>
              </div>
              <div class="count">{{
                  guest_count
                }}
              </div>
              <div
                  class="counter-btn"
                  @click="addQuantityGuests()"
              >
                <i class="fa-solid fa-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>
            {{ $t("from_date") }}
          </label>
          <Calendar v-model="fromDate" :manualInput="true" :minDate="new Date()" class="w-100" ariaLabel="Choose an date" dateFormat="dd/mm/yy"
                    showIcon/>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>
            {{ $t("to_date") }}
          </label>
          <Calendar v-model="toDate" :manualInput="true" :minDate="new Date()" class="w-100" ariaLabel="Choose an date" dateFormat="dd/mm/yy"
                    showIcon/>
        </div>
      </div>
    </div>
    <a :href="whatsappLink" class="btn PrimaryButton w-fit-content" target="_blank" @click="closeDialog">
      {{ $t("contact us") + ' ' + $t("for_book") }}
    </a>
    <!--    {{whatsapp}}-->
  </Dialog>
</template>

<style scoped>
.selects .form-group .resultSearch {
  top: 60px !important;
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 1rem; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: block;
  font-family: "regular", serif;
  opacity: 1;
}

[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}

[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  text-align: center;

  /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}

[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}

[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}

[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
/* [tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}*/

html[lang="en"] .counter-btn,
html[lang="id"] .counter-btn {
  border-radius: 0 0.5rem 0.5rem 0;

  &.right {
    border-radius: 0.5rem 0 0 0.5rem;
  }
}

.counter-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem 0 0 0.5rem;
  border: 1px solid #d0d2d7;
  color: #3f3f3f;
  background-color: #fff;
  transition: all 0.3s ease;

  &.right {
    border-radius: 0 0.5rem 0.5rem 0;
  }
}

.count {
  font-size: 0.8rem;
  font-weight: 500;
  color: #3f3f3f;
  border: 1px solid #d0d2d7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  width: 100%;

}

</style>