<script>
import rate from "@/components/rate.vue";

export default {
  name: "campaign-card",
  components: {rate},
  props: {
    hotel: Object,
    whatsapp: Number,
  },
  computed: {
    whatsappLink() {
      const message =
          `اريد حجز ${this.hotel?.title} فندق\n`;
      return `https://wa.me/${this.whatsapp}?text=${encodeURIComponent(message)}`;
    },
  }
}
</script>

<template>
  <div class="campaign_card">
    <img :src="hotel?.images[0]?.image" :alt="hotel?.title">
    <div class="campaign_card_data">
      <h3>{{ hotel?.title }}</h3>
      <rate :rates="hotel.starCount == 0 ? 5 : hotel.starCount" />
      <ul class="details">
        <li class="d-flex align-items-start justify-content-between">
          <a :href="`https://www.google.com/maps?q=${hotel.lat},${hotel.long}&z=15`"
             target="_blank" class="text-emphasis address">
            <i class="fa-solid fa-location-dot"></i
            >{{ hotel.address }}
          </a>
        </li>
        <li>
          <i class="fa-regular fa-clock"></i> {{ hotel.check_in ?? "14:00" }} /
          {{ hotel.check_out ?? "12:00" }}
        </li>
        <li class="facilities mb-0">
          <span v-for="(facility, index) in hotel.facilities" :key="index" :tooltip="facility.title" flow="up">
            <i :class="`fa-solid fa-${facility.icon}`"></i>
          </span>
        </li>
      </ul>
    </div>
    <div class="campaign_card_book">
      <span class="price">{{ $t("very_special_prices") }}</span>
      <a :href="whatsappLink" target="_blank" class="book">{{ $t("Book now") }} <i class="fa-solid fa-chevron-left"></i></a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .campaign_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid #D0D2D7;
    border-radius: 10px;
    padding: 0.5rem;
    img {
      width: 30%;
      height: 150px;
      object-fit: cover;
      aspect-ratio: 1/2;
      border-radius: 10px;
    }
    .campaign_card_data {
      display: flex;
      flex-direction: column;
      gap: 7px;
      width: 50%;
      h3 {
        font-size: 1.2rem;
        font-family: "regular",serif;
        margin-bottom: 0;
      }
      .details {
        display: flex;
        flex-direction: column;
        gap: 7px;
        .address {
          color: #1F909B;
          text-decoration: underline;
          font-family: "regular",serif;
          font-size: 0.8rem;
          i {
            color: #656B78;
            margin-inline-end: 0.5rem;
          }
        }
        .facilities {
          display: flex;
          gap: 7px;
        }
      }
    }
    .campaign_card_book {
      display: flex;
      flex-direction: column;
      width: 20%;
      .price {
        font-size: 1.1rem;
        font-family: "bold", serif;
        text-align: center;
      }
      .book {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0;
        background-color: #08599B;
        color: #fff;
        border: 1px solid #08599B;
        padding: 0.5rem;
        border-radius: 8px;
        margin-top: 0.35rem;
        font-size: 0.8rem;
        font-family: "bold", serif;
        text-align: center;
        text-decoration: none;
        transition: 0.7s ease-in-out;
        i {
          padding-inline-start: 1rem
        }
        &:hover {
          background-color: transparent;
          border: 1px solid #08599B;
          color: #08599B;
          transition: 0.7s ease-in-out;
        }
      }
    }
  }
</style>