<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <!-- <HeaderEditSearch @runFlight="FetchFlights" /> -->
  <div class="multi_search_trip">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-12 col-12">
          <Filter @FetchFlights="FetchFlights" />
          <!-- <Filter :FetchFlights="FetchFlights" /> -->
        </div>
        <div class="col-lg-9 col-md-12 col-12">
          <div class="head_search">
            <div class="text">
              <h6>{{ $t("choose_your_flight") }}</h6>
              <p>{{ $t("price_notes") }}</p>
            </div>
            <!-- <div class="menu">
              <span>{{ $t("order_by") }}</span>
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ $t("best_sellet") }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="#"> {{ $t("lower_price") }}</a>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
          <!-- <div class="know_now">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <div>
                      <span class="d-block text-end mb-2">
                        {{ $t("know_the_newr") }}
                      </span>
                      <span class="d-block">
                        {{ $t("nearist_trip") }}
                      </span>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      class="form-check"
                      v-for="(day, index) in days"
                      :key="index"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        :name="'dateRadio'"
                        :id="'flexRadioDefault' + index"
                        :value="day.date"
                        v-model="selectedDate"
                        :checked="day.date == 0"
                        @click="handleRadioClick(day.date)"
                      />
                      <label
                        class="form-check-label"
                        :for="'flexRadioDefault' + index"
                      >
                        {{ formatDate(day.date) }} <br />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- v-if="flightData.status == 'one_way'" -->
          <div class="catds_list">
            <div
              class="card_airport"
              v-for="(flight, index) in offers"
              :key="index"
            >
              <div class="image">
                <img
                  v-if="flight.company.image"
                  :src="flight.company.image"
                  alt="egyptian-logo"
                />
                <img
                  v-else
                  :src="DefaultImage"
                  alt="egyptian-logo"
                />
                <p class="name_company">{{ flight.company.name }}</p>
              </div>
              <!-- :filteredRoundTrips="flight.round_trips" -->
              <multiWayflight
                :filteredRoundTrips="filteredRoundTrips"
                :flight="flight"
                :index="index"
                v-if="flightData.status == 'two_way' || flightData.status == 'multi_city'"
              />
              <div class="detials_card">
                <h5>
                  {{ convertedPrice(flight.price.total_amount) }} {{ coinCode }}
                </h5>
                <button
                  class="btn serve_now"
                  type="button"
                  data-bs-toggle="offcanvas"
                  :data-bs-target="`#offcanvasBottom${flight.id}`"
                  :key="`#offcanvasBottom${flight.id}`"
                  >
                  <!-- @click="checkCancellationPolicies(flight)" -->
                  {{ $t("trip_details") }}
                  <i class="fa-solid fa-chevron-down"></i>
                </button>
              </div>
              <flightDetails
                :flight="flight"
                :metadata="metadata"
                :paxs="paxs"
                :offers="offers"
                :cancellation_policies="cancellation_policies"
                :indexFlight="index"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import HeaderEditSearch from "@/components/home/flightHeaderSearch.vue";
import Filter from "@/components/flight/filter.vue";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import flightDetails from "@/components/flight/flightDetails.vue";
import multiWayflight from "@/components/flight/multiTripsCard.vue";

export default {
  name: "flight-search",
  components: {
    // HeaderEditSearch,
    Filter,
    Loading,
    flightDetails,
    multiWayflight,
  },
  data() {
    return {
      flight: [],
      flights: [],
      search: true,
      // currentDate:
      //   JSON.parse(localStorage.getItem("flightData")) == null
      //     ? ""
      //     : JSON.parse(localStorage.getItem("flightData")).flight.arrive_date,
      selectedDate: null, // To store the selected price
      days: [
        { date: -3, price: "100 SR" }, // Three days before the current date
        { date: -2, price: "110 SR" }, // Two days before
        { date: -1, price: "110 SR" }, // One day before
        { date: 0, price: "110 SR" }, // Current date
        { date: 1, price: "100 SR" }, // One day after
        { date: 2, price: "100 SR" }, // Two days after
        { date: 3, price: "120 SR" }, // Three days after
      ],
      coinCode:
        localStorage.getItem("coinCode") == null
          ? "SAR"
          : localStorage.getItem("coinCode"),
      loading: false,
      offers: [],
      metadata: {},
      paxs: [],
      cancellation_rate_basis: null,
      cancellation_policies: {},
      flightData: JSON.parse(localStorage.getItem("flightData")),
      DefaultImage: localStorage.getItem("DefaultImage"),
    };
  },

  methods: {
    async FetchFlights() {
      this.loading = true; //the loading started
      // Retrieve data from local storage
      const flightData = JSON.parse(localStorage.getItem("flightData"));

      try {
        const { data } = await axios.post("/flight/air_shopping", flightData);

        // Set the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', this.$t("flight_search"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'description';
          newMeta.content = this.$t("flight_search");
          document.head.appendChild(newMeta);
        }
        // Set the meta title
        const metaTitle = document.querySelector('meta[name="title"]');
        if (metaTitle) {
          metaTitle.setAttribute('content', this.$t("flight_search"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'title';
          newMeta.content = this.$t("flight_search");
          document.head.appendChild(newMeta);
        }
        // Update the page title
        const titleEl = document.querySelector("head title");
        if (titleEl) {
          titleEl.textContent = this.$t("flight_search");
        } else {
          console.warn("Title element not found!");
        }

        if(data.status == true) {

          // Filter multi-city trips based on conditions
          const multiCityData = flightData.trips;

          const filteredMultiCityTrips = data.data.offers.map((offer) => {
            const trips = multiCityData.map((city) => {
              const launchTrip = offer.round_trips.find(
                (trip) => trip.launch.airport == city.from
              );
              const arriveTrip = offer.round_trips.find(
                (trip) => trip.arrive.airport == city.to
              );

              // Check if both launchLeg and arriveLeg are defined before including in the result
              if (launchTrip !== undefined && arriveTrip !== undefined) {
                return {
                  launchTrip,
                  arriveTrip,
                  date: city.date,
                };
              } else {
                return null; // Exclude from the result if either launchLeg or arriveLeg is undefined
              }
              
            });

            return {
              trips: trips.filter(Boolean),
            };
          });

          // console.log(filteredMultiCityTrips);
  
          this.offers = data.data.offers;
          // console.log(this.offers)
          this.filteredRoundTrips = filteredMultiCityTrips;
          // console.log(this.filteredRoundTrips)
          this.paxs = data.data.paxs;
          this.metadata = data.data.metadata;
          this.loading = false; // The loading ended;
        }
        else if(data.status == false) {
          Swal.fire({
            title: data.message,
            text: data.data.DescText,
            icon: "error",
          });
          this.loading = false; // The loading ended;
        }

      } catch (error) {
        Swal.fire({
          title: "",
          text: error?.response?.data?.message || "Unknown error",
          icon: "error",
        });
        this.loading = false; // The loading ended
      }
    },

    // formatDate(day) {
    //   const date = new Date(this.currentDate);
    //   date.setDate(date.getDate() + day); // Adjust the date
    //   return date.toDateString();
    // },
    // save day in console
    // handleRadioClick(dayDate) {
    //   const date = new Date(this.currentDate);
    //   date.setDate(date.getDate() + dayDate); // Adjust the date
    //   const formatedDate = new Date(date.toDateString());
    //   const year = formatedDate.getFullYear();
    //   const month = String(formatedDate.getMonth() + 1).padStart(2, "0");
    //   const day = String(formatedDate.getDate()).padStart(2, "0");
    //   this.selectedDateFormatted = `${year}-${month}-${day}`;
    //   const fetchArrive_date = JSON.parse(localStorage.getItem("flightData"));
    //   fetchArrive_date.flight.arrive_date = this.selectedDateFormatted;

    //   // Convert the updated flightData object back to a string
    //   const updatedFlightDataString = JSON.stringify(fetchArrive_date);

    //   // Store the updated flightDataString in local storage
    //   localStorage.setItem("flightData", updatedFlightDataString);
    //   this.FetchFlights();
    // },
    checkCancellationPolicies(flight) {
      // console.log(flight)
      let rules = {
        airline_code: flight.company.code,
        arrive_code: flight.round_trips[0].launch.airport,
        arrive_code_date: flight.round_trips[0].launch.date,
        dep_code:
          flight.round_trips[flight.round_trips.length - 1].arrive.airport,
        dep_code_date:
          flight.round_trips[flight.round_trips.length - 1].arrive.date,
        fare_code:
          flight.offeritem[0].FareDetail.FareComponent[0].FareBasisCode,
        fare_type_code:
          flight.offeritem[0].FareDetail.FareComponent[0].FareTypeCode,
        fare_ref_text: flight.offeritem[0].FareDetail.FareRefText,
      };
      // console.log(rules);

      axios.post(`/flight/fare_rule`, rules).then(({ data }) => {
        this.cancellation_policies = data.data.Response.Rules;
        // console.log(data)
      });
    },
  },

  created() {
    // console.log(this.currentDate);
    // if (JSON.parse(localStorage.getItem("flightData"))) {
    //   console.log("true");
    // }
    this.FetchFlights();
  },
};
</script>
<!-- 
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_flight.scss";
@import "./_tripFlight.scss";
</style> -->
