<template>
  <section class="blog py-5" style="min-height: 100dvh">
    <div class="container">
      <img class="blog_image" :src="blog.image" :alt="blog.alt" />
      <h1 class="title mb-4">{{ blog.title }}</h1>
      <p class="blog-content" v-html="blog.content"></p>
      <ul class="px-0 mx-0">
        <li
          class="d-flex justify-content-between align-items-center mt-2"
          v-for="tag in blog.hashtag_blogs"
          :key="tag.id"
        >
          <router-link :to="`/blog/hashtags/${tag.id}`" class="tag">{{
            tag.title
          }}</router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "blog-page",
  data() {
    return {
      blog: {},
    };
  },
  methods: {
    async fetchBlogs() {
      const slug = this.$route.params.slug;
      await axios.get(`/show_web/${slug}`).then((data) => {
        this.blog = data.data.data;

        // Set the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', this.blog.meta_description);
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'description';
          newMeta.content = this.blog.description;
          document.head.appendChild(newMeta);
        }
        // Set the meta title
        const metaTitle = document.querySelector('meta[name="title"]');
        if (metaTitle) {
          metaTitle.setAttribute('content', this.blog.meta_title);
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'title';
          newMeta.content = this.blog.title;
          document.head.appendChild(newMeta);
        }
        // Update the page title
        const titleEl = document.querySelector("head title");
        if (titleEl) {
          titleEl.textContent = this.blog.title;
        } else {
          console.warn("Title element not found!");
        }
        const ogTitle = document.querySelector('meta[property="og:title"]');
        if (ogTitle) {
          ogTitle.setAttribute('content', this.blog.title);
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.property = 'og:title';
          newMeta.content = this.blog.title;
          document.head.appendChild(newMeta);
        }
        const ogDescription = document.querySelector('meta[property="og:description"]');
        if (ogDescription) {
          ogDescription.setAttribute('content', this.blog.description);
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.property = 'og:description';
          newMeta.content = this.blog.description;
          document.head.appendChild(newMeta);
        }
        const ogImage = document.querySelector('meta[property="og:image"]');
        if (ogImage) {
          ogImage.setAttribute('content', this.blog.image);
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.property = 'og:image';
          newMeta.content = this.blog.image;
          document.head.appendChild(newMeta);
        }
      });
    },

    // injectSchema() {
    //   // console.log(JSON.parse(this.$route.query?.blog), "blog");
    //   const blog = JSON.parse(this.$route.query?.blog);
    //   // console.log(hotel, "hotel");
    //   const schemaData = {
    //     "@context": "https://schema.org",
    //     "@type": "BlogPosting",
    //     "name": blog.title.replace(/_/g, ' '),
    //     "url": window.location.href,
    //     "image": blog.image,
    //     "datePublished": blog.date,
    //     "dateModified": blog.date,
    //     "description": blog.content.slice(0, 50).replace(/_/g, ' '),
    //     keywords: blog.category,
    //   };
    //
    //   const script = document.createElement("script");
    //   script.type = "application/ld+json";
    //   script.textContent = JSON.stringify(schemaData);
    //   document.head.appendChild(script);
    //
    //   // console.log("Schema injected: ", schemaData); // Verify schema in console
    // }
  },
  async mounted() {
    this.fetchBlogs();
    // this.injectSchema();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./profile/_blog.scss";
</style> -->
