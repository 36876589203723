<script>
export default {
  name: "footer-layout",
  props: {
    logo: String,
    description: String
  }
}
</script>

<template>
  <footer class="footer mt-5">
    <div class="container">
      <div class="row">
        <img :src="logo" class="logo" alt="logo">
        <p class="description">{{ description }}</p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  padding: 1.5rem 0;
  .container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .logo {
      display: block;
      height: 60px;
      object-fit: scale-down;
      margin: 0 auto;
    }
    .description {
      display: block;
      width: 50%;
      text-align: center;
      margin: 1rem auto 0;
    }
  }
}
</style>