<template>
  <Header />
  <div class="wrapper mt-5">
    <SpecialOffer :sections="sections" />
  </div>
  <div class="container">
    <Places v-if="places.length > 0" :places="places" />
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Places from "@/components/home/PlacesCrads.vue";
import axios from "axios";
import SpecialOffer from "@/components/home/SpecialOffer.vue";
// import moment from "moment/moment";
import {hotelsPage} from "@/store/modules/hotelsPage";
import { mapActions, mapState } from "pinia";

export default {
  name: "hotelView",
  components: { Header, Places, SpecialOffer },
  data() {
    return {
      places: [],
      // sections: [],
    };
  },
  methods: {
    ...mapActions(hotelsPage, ["GetHotelsPage"]),

    fetchPlaces() {
      axios.get("/web_site_homes").then(({ data }) => {
        this.places = data.data.destination.slice(0, 5);

        // Set the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', this.$t("Hotels"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'description';
          newMeta.content = this.$t("Hotels");
          document.head.appendChild(newMeta);
        }
        // Set the meta title
        const metaTitle = document.querySelector('meta[name="title"]');
        if (metaTitle) {
          metaTitle.setAttribute('content', this.$t("Hotels"));
        } else {
          // If the meta tag doesn't exist, create one
          const newMeta = document.createElement('meta');
          newMeta.name = 'title';
          newMeta.content = this.$t("Hotels");
          document.head.appendChild(newMeta);
        }
        // Update the page title
        const titleEl = document.querySelector("head title");
        if (titleEl) {
          titleEl.textContent = this.$t("Hotels");
        } else {
          console.warn("Title element not found!");
        }
      });
    },


  },
  computed: {
    ...mapState(hotelsPage, [
      "sections",
      "hotel"
    ]),
  },

  async mounted() {
    await this.GetHotelsPage();
    this.fetchPlaces();
  }


};
</script>

<style></style>
