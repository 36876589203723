<template>
  <section class="main">
    <section class="parnt blogs">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-12">
            <div class="master">
              <div class="pt-2">
                <h4 class="fw-bold">{{ $t("sections") }}</h4>
              </div>
              <ul class="Departments">
                <li v-for="(Categorey, index) in Categorey" :key="index">
                  <router-link :to="`/blog/category/${Categorey.id}`">{{
                    Categorey.title
                  }}</router-link>
                </li>
              </ul>
              <div class="pt-2">
                <h4 class="fw-bold">{{ $t("hashtags") }}</h4>
                <ul class="Departments">
                  <li v-for="(hashtag, index) in Hashtags" :key="index">
                    <router-link :to="`/blog/hashtags/${hashtag.id}`">{{
                      hashtag.title
                    }}</router-link>
                  </li>
                </ul>
              </div>
              <h4 class="fw-bold">{{ $t("latest articles") }}</h4>
              <div
                class="Latest-articles"
                v-for="(blog, index) in latest_blogs"
                :key="index"
              >
                <router-link
                  :to="`/blog/${blog.slug}`"
                  class="d-flex gap-3 w-100"
                >
                  <div class="photo">
                    <img :src="blog.image" alt="" />
                  </div>
                  <div class="text">
                    <p>{{ blog?.date }}</p>
                    <h4>{{ blog?.title?.substring(0, 150) + " ..." }}</h4>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-12 col-12">
            <div class="row">
              <div
                class="col-lg-6 col-12 col-md-6"
                v-for="(blog, index) in blogs.data"
                :key="index"
              >
                <blogCard :blog="blog" />
              </div>
            </div>
            <div class="row">
              <pagination
                :data="blogs"
                class="mx-auto d-flex align-items-center justify-content-center pagination"
                @pagination-change-page="fetchBlogs"
              >
                <template v-slot:prev-nav>&lt;</template>
                <template v-slot:next-nav>&gt;</template>
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import blogCard from "@/components/blogs/blogCard.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";

export default {
  name: "blogs-page",
  data() {
    return {
      blogs: {},
      latest_blogs: [],
      Categorey: {},

      Hashtags: {},
    };
  },
  components: {
    blogCard,
    pagination,
  },
  methods: {
    async fetchHashtags() {
      await axios.get("/fetch_hashtag_blog").then((data) => {
        this.Hashtags = data.data.data;

        // console.log(this.Hashtags);
      });
    },
    async fetchBlogs() {
      await axios
        .post(`/fetch_blogs_by_hashtag_id`, {
          hashtag_blog_id: this.$route.params.id,
        })
        .then((data) => {
          this.blogs = data.data.data;
          this.latest_blogs = data.data.data.data.slice(-3);
          // console.log(this.blogs);
        });
    },
    async fetchCategorey() {
      await axios.get("/fetch_blog_categories").then((data) => {
        this.Categorey = data.data.data;
        // console.log(this.Categorey);
      });
    },
  },
  created() {
    this.fetchBlogs();
    this.fetchHashtags();
    this.fetchCategorey();
  },
  // watch: {
  //   $route: "fetchBlogs",
  // },

  watch: {
    $route() {
      this.fetchBlogs();
    },
  },
};
</script>

<!-- <style scoped>
    @import "./profile/_blogs.scss";
    </style> -->
